import { ReactComponent as File } from "@/assets/vectors/icn_file.svg";
import { FileInputCard } from "./card";
import { FileInputProperty } from "./property";
import { CardSchema } from "../card_schema";
import { CardType } from "@/generated/protos/element_pb";

export interface FileInputCardProperties {
  label: string;
  supportingText: string;
  required: boolean;
  supportedFiles: string[];
  sizeLimits: number[][];
}

export const fileInputCardConfig: CardSchema = {
  id: "",
  type: CardType.CARD_TYPE_FILE_INPUT,
  label: "File Input",
  icon: File,
  properties: {
    [CardType.CARD_TYPE_FILE_INPUT]: {
      label: "",
      supportingText: "",
      required: true,
      sizeLimits: [
        [0, 500],
        [0, 0],
      ],
      supportedFiles: [
        "jpg, jpeg, gif, png, bmp, tif, psd, pdf, doc, docx, csv, xls, xlsx, txt, mp3, mp4, aac, wav, au, wmv, avi, mpg, mpeg, zip, gz, rar, z, tgz, tar, sitx",
      ],
    },
  },
  enabledGestures: false,
  card: FileInputCard,
  property: FileInputProperty,
};
