import { ReactComponent as Name } from "@/assets/vectors/icn_name.svg";
import { NameCardProperty } from "./property";
import { NameCard } from "./card";
import { CardSchema } from "../card_schema";
import { CardType } from "@/generated/protos/element_pb";

export interface NameCardProperties {
  label: string;
  supportingText: string;
  required: boolean;
}

export const nameCardConfig: CardSchema = {
  id: "",
  type: CardType.CARD_TYPE_NAME,
  label: "Name",
  icon: Name,
  properties: {
    [CardType.CARD_TYPE_NAME]: {
      label: "",
      supportingText: "",
      required: true,
    },
  },
  enabledGestures: false,
  card: NameCard,
  property: NameCardProperty,
};
