import { useControllableState } from "@/common/use_controllable_state";
import React, { LegacyRef } from "react";
import { InputHTMLAttributes } from "react";

interface StandardCardInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  defaultValue?: string;
  value?: string;
  onChange?: (state: string) => void;
}
export const StandardCardInput = React.forwardRef(function (
  props: StandardCardInputProps,
  ref: LegacyRef<HTMLInputElement>
) {
  const [value, setValue] = useControllableState({
    defaultProp: props.defaultValue,
    prop: props.value,
    onChange: props.onChange,
  });

  return (
    <div className="w-full h-full flex px-6 py-3.5 border border-white rounded-3xl items-center">
      <input
        {...props}
        ref={ref}
        type="text"
        defaultValue={value}
        className="outline-none w-full placeholder:text-white text-sm bg-transparent text-white"
        onChange={(event) => setValue((event.target as HTMLInputElement).value)}
      />
    </div>
  );
});
