import { useControllableState } from "@/common/use_controllable_state";
import React, { TextareaHTMLAttributes, useState } from "react";
import { ReactComponent as Star } from "@/assets/vectors/icn_star.svg";
import clsx from "clsx";

interface StandardCardRatingProps
  extends Partial<
    Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "onChange">
  > {
  count: number;
  defaultValue?: number;
  value?: number;
  onChange?: (state: number) => void;
}

export const StandardCardRating = React.forwardRef(function (
  props: StandardCardRatingProps,
  _
) {
  const [value, setValue] = useControllableState({
    defaultProp: props.defaultValue,
    prop: props.value,
    onChange: props.onChange,
  });

  const [hoverCount, setHoverCount] = useState(value);

  return (
    <div className="w-full flex justify-center">
      {Array.from({ length: props.count || 5 }).map((_, index) => (
        <Star
          className={clsx(
            "stroke-white w-10 h-10 fill-transparent hover:fill-yellow-500 transition-all duration-300 cursor-pointer",
            {
              "fill-yellow-500": index <= (hoverCount || 0) - 1,
            }
          )}
          key={index}
          onMouseEnter={() => setHoverCount(index)}
          onClick={() => props.disabled && setValue(index)}
        />
      ))}
    </div>
  );
});
