import { StandardCardMatches } from "../../card_form";
import { StandardForm, StandardFormField } from "../../form";
import { ReactComponent as Ahead } from "@/assets/vectors/icn_ahead.svg";
import { CardProperties } from "../card_schema";
import clsx from "clsx";
import { CardType } from "@/generated/protos/element_pb";
import { useForm } from "react-hook-form";

interface TextMatchCardProps extends CardProperties {}

export function TextMatchCard(props: Partial<TextMatchCardProps>) {
  const properties = props?.[CardType.CARD_TYPE_TEXT_MATCH] || {};

  const form = useForm({
    values: props[CardType.CARD_TYPE_TEXT_MATCH],
  });

  const onContinue = async () => {
    await form.trigger();
    if (!Object.keys(form.formState.errors).length) {
      props.onContinue?.({
        [CardType.CARD_TYPE_TEXT_MATCH]: form.getValues(),
      });
    }
  };

  return (
    <div className="px-4 py-[46px] flex flex-col">
      <span className="text-[32px] font-bold leading-9 text-white text-center">
        {properties.label || "Match"}
      </span>
      <span className="text-white text-lg leading-7 my-4 text-center">
        {properties.supportingText ||
          "Welcome to our new hire onboarding program! This comprehensive journey is."}
      </span>

      <div className="my-8">
        <StandardForm form={form}>
          <div className="flex items-center gap-6">
            <StandardFormField
              name="matches"
              registerOptions={{ required: "This field is required" }}
            >
              <StandardCardMatches />
            </StandardFormField>
          </div>
        </StandardForm>
      </div>

      <div
        className={clsx(
          "flex items-center bg-black w-[128px] h-10 rounded-3xl justify-center",
          {
            "hover:opacity-50 transition-all cursor-pointer": !props.disabled,
            "cursor-not-allowed pointer-events-none": props.disabled,
          }
        )}
        onClick={onContinue}
      >
        <span className="mr-2 text-white">Continue</span>
        <Ahead className="stroke-white w-6 h-6" />
      </div>
    </div>
  );
}
