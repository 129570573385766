import { CardType } from "@/generated/protos/element_pb";
import { addressOptions } from ".";
import {
  StandardCheckboxGroup,
  StandardForm,
  StandardFormField,
  StandardInput,
  StandardToogle,
} from "../../form";
import { CardProperties } from "../card_schema";
import { useForm } from "react-hook-form";

interface AddressCardPropertyProps extends Partial<CardProperties> {}

export const AddressCardProperty = (props: AddressCardPropertyProps) => {
  const form = useForm({
    defaultValues: props[CardType.CARD_TYPE_ADDRESS],
  });

  return (
    <StandardForm
      form={form}
      onFormChange={(value) =>
        props.onChange?.({ [CardType.CARD_TYPE_ADDRESS]: value })
      }
    >
      <div className="flex flex-col gap-8">
        <StandardFormField<string> name="label" label="Label">
          {(props) => (
            <div className="h-9">
              <StandardInput {...props} placeholder="ex. Short Answer" />
            </div>
          )}
        </StandardFormField>

        <StandardFormField<string>
          name="supportingText"
          label="Supporting Text"
        >
          {(props) => (
            <div className="h-9">
              <StandardInput
                {...props}
                placeholder="ex. This is supporting text"
              />
            </div>
          )}
        </StandardFormField>

        <StandardFormField<string> name="address" label="Advanced options">
          <StandardCheckboxGroup
            options={addressOptions}
            direction="vertical"
          />
        </StandardFormField>

        <StandardFormField
          name="required"
          label="Required?"
          description="Will require user to respond to continue"
        >
          <StandardToogle />
        </StandardFormField>
      </div>
    </StandardForm>
  );
};
