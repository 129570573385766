import { CardType } from "@/generated/protos/element_pb";
import {
  StandardFileSizeLimit,
  StandardForm,
  StandardFormField,
  StandardInput,
  StandardTextarea,
  StandardToogle,
} from "../../form";
import { CardProperties } from "../card_schema";
import { useForm } from "react-hook-form";

interface FileInputPropertyProps extends Partial<CardProperties> {}

export const FileInputProperty = (props: FileInputPropertyProps) => {
  const form = useForm({
    defaultValues: props[CardType.CARD_TYPE_FILE_INPUT],
  });
  return (
    <StandardForm
      form={form}
      onFormChange={(value) =>
        props.onChange?.({ [CardType.CARD_TYPE_FILE_INPUT]: value })
      }
    >
      <div className="flex flex-col gap-8">
        <StandardFormField<string> name="label" label="Label">
          {(props) => (
            <div className="h-9">
              <StandardInput {...props} placeholder="ex. Your name" />
            </div>
          )}
        </StandardFormField>

        <StandardFormField<string>
          name="supportingText"
          label="Supporting Text"
        >
          {(props) => (
            <div className="h-9">
              <StandardInput
                placeholder="ex. This is supporting text"
                {...props}
              />
            </div>
          )}
        </StandardFormField>

        <StandardFormField name="sizeLimits" label="Size limits">
          <StandardFileSizeLimit />
        </StandardFormField>

        <StandardFormField<string>
          name="supportedFiles"
          label="Supported files"
        >
          <StandardTextarea placeholder="jpg, jpeg, gif, png, bmp, tif, psd, pdf, doc, docx, csv, xls, xlsx, txt, mp3, mp4, aac, wav, au, wmv, avi, mpg, mpeg, zip, gz, rar, z, tgz, tar, sitx" />
        </StandardFormField>

        <StandardFormField
          name="required"
          label="Required?"
          description="Will require user to respond to continue"
        >
          <StandardToogle />
        </StandardFormField>
      </div>
    </StandardForm>
  );
};
