import { CardType } from "@/generated/protos/element_pb";
import {
  StandardForm,
  StandardFormField,
  StandardInput,
  StandardTextarea,
  StandardToogle,
} from "../../form";
import { CardProperties } from "../card_schema";
import { useForm } from "react-hook-form";

interface SignatureCardPropertyProps extends Partial<CardProperties> {}

export const SignatureCardProperty = (props: SignatureCardPropertyProps) => {
  const form = useForm({
    defaultValues: props[CardType.CARD_TYPE_SIGNATURE],
  });
  return (
    <StandardForm
      form={form}
      onFormChange={(value) =>
        props.onChange?.({
          [CardType.CARD_TYPE_SIGNATURE]: value,
        })
      }
    >
      <div className="flex flex-col gap-8">
        <StandardFormField<string> name="label" label="Label">
          {(props) => (
            <div className="h-9">
              <StandardInput {...props} placeholder="ex. Signature" />
            </div>
          )}
        </StandardFormField>

        <StandardFormField<string>
          name="supportingText"
          label="Supporting Text"
          description="What’s your assistants background, profession, and expertise?"
        >
          <StandardTextarea placeholder="By signing below you’re agreeing to something that you should already know about." />
        </StandardFormField>

        <StandardFormField
          name="required"
          label="Required?"
          description="Will require user to respond to continue"
        >
          <StandardToogle />
        </StandardFormField>
      </div>
    </StandardForm>
  );
};
