import { atom } from "jotai";

export enum ToastType {
  default,
  info,
  success,
  warning,
  error,
}

interface ToastProps {
  type?: ToastType;
  duration: number;
  message: string;
}

const _toastAtom = atom<ToastProps[]>([]);

export const toastAtom = atom(
  (get) => get(_toastAtom),
  (get, set, update: ToastProps) => {
    const currentValue = get(_toastAtom);

    setTimeout(() => {
      set(
        _toastAtom,
        get(_toastAtom).filter((t) => t !== update)
      );
    }, update.duration * 1000);

    set(_toastAtom, [update, ...currentValue]);
  }
);
