import { useControllableState } from "@/common/use_controllable_state";
import React, { LegacyRef, TextareaHTMLAttributes } from "react";

interface StandardCardTextareaProps
  extends Partial<
    Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "onChange">
  > {
  defaultValue?: string;
  value?: string;
  onChange?: (state: string) => void;
}

export const StandardCardTextarea = React.forwardRef(function (
  props: StandardCardTextareaProps,
  ref: LegacyRef<HTMLTextAreaElement>
) {
  const [value, setValue] = useControllableState({
    defaultProp: props.defaultValue,
    prop: props.value,
    onChange: props.onChange,
  });

  return (
    <div className="w-full min-h-[108px] h p-4 border border-white rounded-3xl">
      <textarea
        {...props}
        ref={ref}
        defaultValue={value}
        className="w-full min-h-[108px] outline-none flex-1 placeholder:text-white placeholder:opacity-50 text-sm bg-transparent text-white"
        onChange={(event) =>
          setValue((event.target as HTMLTextAreaElement).value)
        }
      />
    </div>
  );
});
