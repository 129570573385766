import { useControllableState } from "@/common/use_controllable_state";
import React, { LegacyRef } from "react";
import { InputHTMLAttributes } from "react";
import { omit } from "lodash";

interface StandardNumberInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  value?: number;
  defaultValue?: number;
  unit?: string;
  onChange?: (state: number) => void;
  renderRightPrefix?: () => React.ReactNode;
}

export const StandardNumberInput = React.forwardRef(function (
  props: StandardNumberInputProps,
  ref: LegacyRef<HTMLInputElement>
) {
  const [value, setValue] = useControllableState({
    defaultProp: props.defaultValue,
    prop: props.value,
    onChange: props.onChange,
  });

  return (
    <div className="w-full h-full flex py-2.5 px-4 border border-black border-opacity-10 rounded-2xl items-center relative">
      <input
        {...omit(props, ["unit", "renderRightPrefix"])}
        ref={ref}
        type="number"
        defaultValue={value}
        className="outline-none no-spin text-sm flex-1 w-full"
        onChange={(event) =>
          setValue(Number((event.target as HTMLInputElement).value))
        }
      />
      {props.unit && <span className="text-sm mx-4">{props.unit}</span>}
      {props?.renderRightPrefix?.()}
    </div>
  );
});
