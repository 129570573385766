import { ReactComponent as Back } from "@/assets/vectors/icn_back.svg";
import { ReactComponent as Add } from "@/assets/vectors/icn_add.svg";
import { ReactComponent as Zoom } from "@/assets/vectors/icn_zoom.svg";
import { ReactComponent as Delete } from "@/assets/vectors/icn_delete.svg";
import { cardMap, CardSchema } from "@/common/components/cards";
import { ReactComponent as Warning } from "@/assets/vectors/icn_warning.svg";
import { useAtom } from "jotai";
import { currentCardAtom } from "@/stores/course_atom";
import clsx from "clsx";
import { useEffect, useMemo } from "react";
import { ReactSortable } from "react-sortablejs";
import {
  useBatchEditSort,
  useCreateCardByCourseId,
  useDeleteCardById,
  useEditCardById,
  useGetCoursesById,
  useListCardByCourseId,
} from "@/hooks";
import {
  DropdownMenuItemProps,
  StandardDropdownMenu,
  StandardDropdownMenuPosition,
  StandardDropdownMenuType,
  StandardLoadingIndicator,
} from "@/common";
import { CardType, CardTypeMap } from "@/generated/protos/element_pb";
import { useNavigate, useParams } from "react-router-dom";
import { pleaseWaitAtom } from "@/stores";
import { cloneDeep } from "lodash";

export function BuildCoursePage() {
  const [currentCard, setCurrentCard] = useAtom(currentCardAtom);
  const [_, setPleaseWait] = useAtom(pleaseWaitAtom);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { pending, cardsByCourseId, ListCardByCourseId, setCardsByCourseId } =
    useListCardByCourseId();

  const { CreateCardByCourseId } = useCreateCardByCourseId();

  const { EditCardById } = useEditCardById();

  const { DeleteCardById } = useDeleteCardById();

  const { BatchEditSort } = useBatchEditSort();

  const { courseDetail, GetCoursesById } = useGetCoursesById();

  useEffect(() => {
    ListCardByCourseId({ courseId: id! });
    GetCoursesById({ id: id! });
  }, []);

  const onSave = async () => {
    try {
      setPleaseWait(true);
      await EditCardById({
        card: {
          properties: JSON.stringify(currentCard?.properties),
          type: currentCard?.type!,
          id: currentCard?.id!,
        },
      });
      await ListCardByCourseId({ courseId: id! });
    } finally {
      setPleaseWait(false);
    }
  };

  const onDelete = async () => {
    try {
      setPleaseWait(true);
      await DeleteCardById({ id: currentCard?.id! });
      const nextListCardByCourseId = await ListCardByCourseId({
        courseId: id!,
      });
      if (nextListCardByCourseId?.length) {
        setCurrentCard(nextListCardByCourseId[0]);
      }
    } finally {
      setPleaseWait(false);
    }
  };

  const onSort = async (oldIndex: number, newIndex: number) => {
    const cards = cloneDeep(cardsByCourseId);
    const tmp = cards[oldIndex];
    cards[oldIndex] = cards[newIndex];
    cards[newIndex] = tmp;
    try {
      setPleaseWait(true);
      await BatchEditSort({
        cardsList: cards.map((card, index) => ({ id: card.id, sort: index })),
      });
    } finally {
      setPleaseWait(false);
    }
  };

  const onCreateCard = async (cardType: CardTypeMap[keyof CardTypeMap]) => {
    try {
      setPleaseWait(true);
      const { type, properties, sort } = cardMap[cardType];
      const nextCard = await CreateCardByCourseId({
        card: {
          type,
          properties: JSON.stringify(properties),
          sort,
        },
        courseId: id,
      });
      if (nextCard) {
        setCurrentCard({
          ...cardMap[nextCard.type],
          id: nextCard.id,
          properties: JSON.parse(nextCard.properties),
        });
        await ListCardByCourseId({ courseId: id! });
      }
    } finally {
      setPleaseWait(false);
    }
  };

  const options = useMemo<DropdownMenuItemProps[][]>(() => {
    return [
      [
        {
          label: cardMap[CardType.CARD_TYPE_PHOTO].label,
          type: StandardDropdownMenuType.default,
          icon: cardMap[CardType.CARD_TYPE_PHOTO].icon,
          onClick: () => onCreateCard(CardType.CARD_TYPE_PHOTO),
        },
        {
          label: cardMap[CardType.CARD_TYPE_VIDEO].label,
          type: StandardDropdownMenuType.default,
          icon: cardMap[CardType.CARD_TYPE_VIDEO].icon,
          onClick: () => onCreateCard(CardType.CARD_TYPE_VIDEO),
        },
      ],
      [
        {
          label: cardMap[CardType.CARD_TYPE_FILE_INPUT].label,
          type: StandardDropdownMenuType.default,
          icon: cardMap[CardType.CARD_TYPE_FILE_INPUT].icon,
          onClick: () => onCreateCard(CardType.CARD_TYPE_FILE_INPUT),
        },
        {
          label: cardMap[CardType.CARD_TYPE_EMAIL].label,
          type: StandardDropdownMenuType.default,
          icon: cardMap[CardType.CARD_TYPE_EMAIL].icon,
          onClick: () => onCreateCard(CardType.CARD_TYPE_EMAIL),
        },
        {
          label: cardMap[CardType.CARD_TYPE_FREE_RESPONSE].label,
          type: StandardDropdownMenuType.default,
          icon: cardMap[CardType.CARD_TYPE_FREE_RESPONSE].icon,
          onClick: () => onCreateCard(CardType.CARD_TYPE_FREE_RESPONSE),
        },
        {
          label: cardMap[CardType.CARD_TYPE_ADDRESS].label,
          type: StandardDropdownMenuType.default,
          icon: cardMap[CardType.CARD_TYPE_ADDRESS].icon,
          onClick: () => onCreateCard(CardType.CARD_TYPE_ADDRESS),
        },
        {
          label: cardMap[CardType.CARD_TYPE_PHONE_NUMBER].label,
          type: StandardDropdownMenuType.default,
          icon: cardMap[CardType.CARD_TYPE_PHONE_NUMBER].icon,
          onClick: () => onCreateCard(CardType.CARD_TYPE_PHONE_NUMBER),
        },
        {
          label: cardMap[CardType.CARD_TYPE_RATING].label,
          type: StandardDropdownMenuType.default,
          icon: cardMap[CardType.CARD_TYPE_RATING].icon,
          onClick: () => onCreateCard(CardType.CARD_TYPE_RATING),
        },
        {
          label: cardMap[CardType.CARD_TYPE_SIGNATURE].label,
          type: StandardDropdownMenuType.default,
          icon: cardMap[CardType.CARD_TYPE_SIGNATURE].icon,
          onClick: () => onCreateCard(CardType.CARD_TYPE_SIGNATURE),
        },
      ],
      [
        {
          label: cardMap[CardType.CARD_TYPE_NAME].label,
          type: StandardDropdownMenuType.default,
          icon: cardMap[CardType.CARD_TYPE_NAME].icon,
          onClick: () => onCreateCard(CardType.CARD_TYPE_NAME),
        },
        {
          label: cardMap[CardType.CARD_TYPE_TEXT_MATCH].label,
          type: StandardDropdownMenuType.default,
          icon: cardMap[CardType.CARD_TYPE_TEXT_MATCH].icon,
          onClick: () => onCreateCard(CardType.CARD_TYPE_TEXT_MATCH),
        },
      ],
    ];
  }, [onCreateCard]);

  const Preview = useMemo(() => {
    if (currentCard) {
      const config = cardMap[currentCard?.type!];
      const Property = config.property;
      const Card = config.card;
      return (
        <>
          <div className="flex-1 flex flex-col">
            <div className="mb-8 flex justify-between items-center">
              <span className="text-[22px] font-semibold">Edit Card</span>
              <div className="flex items-center">
                <div
                  className="w-16 h-9 bg-primary-black flex justify-center items-center text-white rounded-2xl text-sm cursor-pointer"
                  onClick={onSave}
                >
                  Save
                </div>
                <div
                  className="w-9 h-9 flex items-center justify-center rounded-full border border-black border-opacity-10 ml-4 cursor-pointer"
                  onClick={onDelete}
                >
                  <Delete className="w-4 stroke-primary-black" />
                </div>
              </div>
            </div>

            <div className="w-[420px]">
              <Property
                {...currentCard.properties}
                onChange={(properties) =>
                  setCurrentCard({ ...currentCard, properties })
                }
              />
            </div>
          </div>
          <div
            className={clsx(
              "w-[468px] rounded-[48px] p-6 flex-shrink-0 h-[700px] flex flex-col overflow-scroll transition-all duration-500",
            )}
            style={{
              background: courseDetail?.backgroundColor
            }}
          >
            <div className="flex items-center justify-between mb-6 sticky top-0">
              <div className="flex items-center">
                <img
                  className="w-10 h-10 rounded-full object-cover"
                  src={`/api/v1/files/${courseDetail?.logo}`}
                />
                <span className="text-lg ml-4 text-white">
                  {courseDetail?.courseName}
                </span>
              </div>
              <Zoom className="opacity-50 w-6 h-6 stroke-white cursor-pointer" />
            </div>
            <Card {...currentCard.properties} disabled={true} />
          </div>
        </>
      );
    }

    return (
      <div className="flex w-full">
        <div className="flex-1 opacity-50 flex flex-col items-center h-full justify-center border border-dashed border-black border-opacity-10 rounded-[48px]">
          <Warning className="w-4 h-4 stroke-primary-black mb-4" />
          <span className="text-[10px]">
            No content to display. Please start by adding a card to your module.
          </span>
        </div>
      </div>
    );
  }, [currentCard, cardsByCourseId, courseDetail]);

  useEffect(() => {
    return () => {
      setCurrentCard(undefined);
    };
  }, []);

  return (
    <div className="w-[1200px] min-h-[700px] py-6 flex gap-16">
      <div
        className="flex w-[184px] rounded-[48px] p-4 flex-col justify-between flex-shrink-0 h-[700px]"
        style={{ boxShadow: "0px 0px 18px rgba(0, 0, 0, 0.1)" }}
      >
        <div
          className="flex items-center w-full h-10 px-3 py-4 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <Back className="opacity-50 w-4 h-4 stroke-primary-black" />
          <span className="text-sm font-medium ml-4">Back</span>
        </div>

        <div className="w-full h-[1px] bg-gray my-4"></div>

        <ReactSortable<CardSchema>
          className="flex-1 w-full flex flex-col gap-2 h-[300px] mb-16"
          list={JSON.parse(JSON.stringify(cardsByCourseId))}
          animation={200}
          onSort={({ oldIndex, newIndex }) => onSort(oldIndex!, newIndex!)}
          setList={setCardsByCourseId}
          group="config"
        >
          {cardsByCourseId.map((card) => {
            const Icon = cardMap[card.type!].icon;
            return (
              <div
                key={card.id}
                className={clsx(
                  "flex items-center justify-between h-10 px-4 rounded-2xl cursor-pointer hover:bg-gray",
                  {
                    "bg-gray": card.id === currentCard?.id,
                  }
                )}
                onClick={() => setCurrentCard(card)}
              >
                <span className="font-medium text-sm">{card.label}</span>
                <Icon className="w-4 h-4 stroke-primary-black" />
              </div>
            );
          })}
        </ReactSortable>
        {pending ? (
          <StandardLoadingIndicator />
        ) : (
          <StandardDropdownMenu
            options={options}
            position={StandardDropdownMenuPosition.top_start}
            offsetL={140}
          >
            <div className="hover:bg-black hover:opacity-50 flex items-center justify-center bg-black rounded-2xl h-10 cursor-pointer transition-all">
              <span className="text-white text-sm font-medium">Add card</span>
              <Add className="ml-2 stroke-white w-4 h-4" />
            </div>
          </StandardDropdownMenu>
        )}
      </div>

      {Preview}
    </div>
  );
}
