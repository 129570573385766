import { CancelToken } from "axios";
import { ApiClient } from "./api_client";

export class FileService {
  private constructor() {}

  public static async uploadFile(
    formData: FormData,
    cancelToken?: CancelToken
  ) {
    const response = await ApiClient.getInstance().postFormDataMessage<string>({
      url: "api/v1/files/upload",
      data: formData,
      cancelToken,
    });

    return response;
  }
}
