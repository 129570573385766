import { cardMap, CardProperties, StandardLoadingIndicator } from "@/common";
import { useGetCoursesById, useListCardByCourseId } from "@/hooks";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export function PreviewPage() {
  const { id } = useParams<{ id: string }>();
  const [step, setStep] = useState(0);

  const {
    pending: cardPending,
    cardsByCourseId,
    ListCardByCourseId,
  } = useListCardByCourseId();

  const {
    pending: coursePending,
    courseDetail,
    GetCoursesById,
  } = useGetCoursesById();

  useEffect(() => {
    GetCoursesById({ id: id! });
    ListCardByCourseId({ courseId: id! });
  }, []);

  const { type, properties } = cardsByCourseId[step] || {};
  const { card: Card, enabledGestures } = cardMap[type] || {};
  const nextValue = {};

  const onBack = () => {
    if (step === 0) return;
    setStep(step - 1);
  };

  const onAhead = () => {
    if (step === cardsByCourseId.length - 1) return;
    setStep(step + 1);
  };

  const onContinue = (value: Partial<CardProperties>) => {
    onAhead();
  };

  return cardPending || coursePending || cardsByCourseId.length === 0 ? (
    <div className="h-screen w-screen flex items-center justify-center">
      <StandardLoadingIndicator />
    </div>
  ) : (
    <div className="pt-safe-top pb-safe-bottom relative">
      <div
        className="p-6 flex flex-col min-h-screen"
        style={{ backgroundColor: courseDetail?.backgroundColor }}
      >
        <div
          className="flex items-center justify-between mb-6 sticky top-0"
        >
          <div className="flex items-center">
            <img
              className="w-10 h-10 rounded-full object-cover"
              src={`/api/v1/files/${courseDetail?.logo}`}
            />
            <span className="text-lg ml-4 text-white">
              {courseDetail?.courseName}
            </span>
          </div>
        </div>
        <Card {...properties} onContinue={onContinue} />
      </div>

      {enabledGestures && (
        <div className={clsx("fixed left-0 top-0 w-screen h-screen flex z-0")}>
          <div className="flex-1 h-screen" onClick={onBack}></div>
          <div className="flex-1 h-screen" onClick={onAhead}></div>
        </div>
      )}
    </div>
  );
}
