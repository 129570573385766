import { useControllableState } from "@/common/use_controllable_state";
import React, { LegacyRef } from "react";
import { cloneDeep, omit } from "lodash";
import { StandardInput } from "./standard_input";
import { ReactComponent as Cancel } from "@/assets/vectors/icn_cancel.svg";
import { ReactComponent as Add } from "@/assets/vectors/icn_add.svg";

interface StandardMatchesProps {
  value?: string[][];
  defaultValue?: string[][];
  onChange?: (state: string[][]) => void;
}

export const StandardMatches = React.forwardRef(function (
  props: StandardMatchesProps,
  _: LegacyRef<HTMLInputElement>
) {
  const [value, setValue] = useControllableState({
    defaultProp: props.defaultValue,
    prop: props.value,
    onChange: props.onChange,
  });

  const nextProps = omit(props, ["defaultValue", "value", "onChange"]);

  return (
    <div>
      <div className="flex flex-col gap-4">
        {value?.map((matchItem, index) => (
          <div className="flex items-center" key={index}>
            <div className="h-9">
              <StandardInput
                {...nextProps}
                defaultValue={matchItem[0]}
                placeholder="Correct Matches"
                onChange={(nextVal) => {
                  const cloneValue = cloneDeep(value);
                  cloneValue[index][0] = nextVal;
                  setValue(cloneValue);
                }}
              />
            </div>
            <span className="w-6 flex-shrink-0 text-center text-black opacity-10">
              =
            </span>
            <div className="h-9">
              <StandardInput
                {...nextProps}
                defaultValue={matchItem[1]}
                placeholder="Correct Matches"
                onChange={(nextVal) => {
                  const cloneValue = cloneDeep(value);
                  cloneValue[index][1] = nextVal;
                  setValue(cloneValue);
                }}
              />
            </div>

            {value.length !== 1 && (
              <div
                className="w-9 h-9 flex items-center justify-center flex-shrink-0 cursor-pointer"
                onClick={() =>
                  setValue(value.filter((item) => matchItem !== item))
                }
              >
                <Cancel className="w-4 h-4 stroke-primary-black" />
              </div>
            )}
          </div>
        ))}
      </div>

      <div
        className="flex items-center mt-4 opacity-50 cursor-pointer"
        onClick={() => setValue([...(value || []), []])}
      >
        <Add className="w-4 h-4 mr-2 stroke-primary-black" />
        <span className="text-sm">Add option</span>
      </div>
    </div>
  );
});
