import { ReactComponent as Email } from "@/assets/vectors/icn_email.svg";
import { EmailCardProperty } from "./property";
import { EmailCard } from "./card";
import { CardSchema } from "../card_schema";
import { CardType } from "@/generated/protos/element_pb";

export interface EmailCardProperties {
  label: string;
  supportingText: string;
  placeholder: string;
  required: boolean;
}

export const emailCardConfig: CardSchema = {
  id: "",
  type: CardType.CARD_TYPE_EMAIL,
  label: "Email",
  icon: Email,
  properties: {
    [CardType.CARD_TYPE_EMAIL]: {
      label: "",
      supportingText: "",
      placeholder: "",
      required: true,
    },
  },
  enabledGestures: false,
  card: EmailCard,
  property: EmailCardProperty,
};
