import { cardsByCourseIdAtom } from "@/stores/course_atom";
import { useAtom } from "jotai";
import { CardService } from "@/services";
import {
  BatchEditCardSortRequest,
  CreateCardByCourseIdRequest,
  DeleteCardByIdRequest,
  EditCardByIdRequest,
  ListCardByCourseIdRequest,
} from "@/generated/protos/card_pb";
import { useState } from "react";
import { cardMap } from "@/common/components/cards";
import { DeepPartial } from "react-hook-form";

export function useListCardByCourseId() {
  const [cardsByCourseId, setCardsByCourseId] = useAtom(cardsByCourseIdAtom);
  const [pending, setPending] = useState(false);

  async function ListCardByCourseId(
    payload: ListCardByCourseIdRequest.AsObject
  ) {
    try {
      if (!pending) {
        setPending(true);
        const cards = (await CardService.ListCardByCourseId(payload)) || [];

        const nextCardsByCourseId =
          cards.map((card) => ({
            label: cardMap[card.type].label,
            type: card.type,
            id: card.id,
            properties: JSON.parse(card.properties || "{}"),
            sort: card.sort,
          })) || [];
        setCardsByCourseId(nextCardsByCourseId);
        return nextCardsByCourseId;
      }
    } finally {
      setPending(false);
    }
  }

  return {
    pending,
    cardsByCourseId,
    setCardsByCourseId,
    ListCardByCourseId,
  };
}

export function useCreateCardByCourseId() {
  async function CreateCardByCourseId(
    payload: DeepPartial<CreateCardByCourseIdRequest.AsObject>
  ) {
    return CardService.CreateCardByCourseId({
      courseId: payload.courseId,
      card: payload.card,
    });
  }

  return {
    CreateCardByCourseId,
  };
}

export function useEditCardById() {
  async function EditCardById(
    payload: DeepPartial<EditCardByIdRequest.AsObject>
  ) {
    return CardService.EditCardById({
      card: payload.card,
    });
  }
  return {
    EditCardById,
  };
}

export function useDeleteCardById() {
  async function DeleteCardById(payload: DeleteCardByIdRequest.AsObject) {
    return CardService.DeleteCardById({
      id: payload.id,
    });
  }

  return {
    DeleteCardById,
  };
}

export function useBatchEditSort() {
  async function BatchEditSort(
    payload: DeepPartial<BatchEditCardSortRequest.AsObject>
  ) {
    return CardService.BatchEditSort(payload);
  }

  return {
    BatchEditSort,
  };
}
