import { CardBaseSchema } from "@/common/components/cards";
import { Course } from "@/generated/protos/courses_pb";
import { atomWithStorage } from "jotai/utils";

export const coursesAtom = atomWithStorage<Course.AsObject[]>("courses", []);

export const courseDetailAtom = atomWithStorage<Course.AsObject | undefined>(
  "courseDetail",
  undefined
);

export const cardsByCourseIdAtom = atomWithStorage<CardBaseSchema[]>(
  "cardsByCourseId",
  []
);

export const currentCardAtom = atomWithStorage<CardBaseSchema | undefined>(
  "currentCard",
  undefined
);
