import { useControllableState } from "@/common/use_controllable_state";
import React, { LegacyRef } from "react";
import { omit } from "lodash";
import { StandardCardInput } from "./standard_card_input";

interface StandardCardMatchesProps {
  value?: string[][];
  defaultValue?: string[][];
  onChange?: (state: string[][]) => void;
}

export const StandardCardMatches = React.forwardRef(function (
  props: StandardCardMatchesProps,
  _: LegacyRef<HTMLInputElement>
) {
  const [value, __] = useControllableState({
    defaultProp: props.defaultValue,
    prop: props.value,
    onChange: props.onChange,
  });

  const nextProps = omit(props, ["defaultValue", "value", "onChange"]);

  return (
    <div>
      <div className="flex flex-col gap-4">
        {value?.map((matchItem, index) => (
          <div className="flex items-center" key={index}>
            <div className="h-14">
              <StandardCardInput
                disabled
                {...nextProps}
                defaultValue={matchItem[0]}
                placeholder="Correct Matches"
              />
            </div>
            <span className="w-6 flex-shrink-0 text-center text-white opacity-50">
              =
            </span>
            <div className="h-14">
              <StandardCardInput
                disabled
                {...nextProps}
                defaultValue={matchItem[1]}
                placeholder="Correct Matches"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});
