import React, { useEffect } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";

interface StandardFormProps<T extends FieldValues>
  extends Partial<Omit<HTMLFormElement, "children">> {
  form: UseFormReturn<T>;
  children: React.ReactNode;
  onFormChange?: (data: T) => void;
  onSubmit?: (data: T) => void;
}

export const FormContext = React.createContext({ form: {} as UseFormReturn });

export function StandardForm<T extends FieldValues>(
  props: StandardFormProps<T>
) {
  useEffect(() => {
    const subscription = props.form.watch((values) => {
      props.onFormChange?.(values as T);
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <FormContext.Provider value={{ form: props.form as UseFormReturn }}>
      <form
        onSubmit={props.form.handleSubmit((data) => props.onSubmit?.(data))}
      >
        {props.children}
      </form>
    </FormContext.Provider>
  );
}
