import { CardType } from "@/generated/protos/element_pb";
import {
  StandardForm,
  StandardFormField,
  StandardInput,
  StandardToogle,
} from "../../form";
import { CardProperties } from "../card_schema";
import { useForm } from "react-hook-form";

interface NameCardPropertyProps extends Partial<CardProperties> {}

export const NameCardProperty = (props: NameCardPropertyProps) => {
  const form = useForm({
    defaultValues: props[CardType.CARD_TYPE_NAME],
  });
  return (
    <StandardForm
      form={form}
      onFormChange={(value) =>
        props?.onChange?.({ [CardType.CARD_TYPE_NAME]: value })
      }
    >
      <div className="flex flex-col gap-8">
        <StandardFormField<string> name="label" label="Label">
          {(props) => (
            <div className="h-9">
              <StandardInput {...props} placeholder="ex. Your name" />
            </div>
          )}
        </StandardFormField>

        <StandardFormField<string>
          name="supportingText"
          label="Supporting Text"
        >
          {(props) => (
            <div className="h-9">
              <StandardInput
                placeholder="ex. This is supporting text"
                {...props}
              />
            </div>
          )}
        </StandardFormField>

        <StandardFormField
          name="required"
          label="Required?"
          description="Will require user to respond to continue"
        >
          <StandardToogle />
        </StandardFormField>
      </div>
    </StandardForm>
  );
};
