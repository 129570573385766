import { ReactComponent as Name } from "@/assets/vectors/icn_name.svg";
import { TextMatchCardProperty } from "./property";
import { TextMatchCard } from "./card";
import { CardSchema } from "../card_schema";
import { CardType } from "@/generated/protos/element_pb";

export interface TextMatchCardProperties {
  label: string;
  supportingText: string;
  required: boolean;
  matches: string[][];
}

export const textMatchCardConfig: CardSchema = {
  id: "",
  type: CardType.CARD_TYPE_TEXT_MATCH,
  label: "Text Match",
  icon: Name,
  properties: {
    [CardType.CARD_TYPE_TEXT_MATCH]: {
      label: "",
      supportingText: "",
      required: true,
      matches: [
        ["John Smith", "Chief of Staff"],
        ["Brianna Jacobs", "Founder"],
        ["Trevor Henry", "CMO"],
        ["Devin Silvers", "VP of Legal"],
      ],
    },
  },
  enabledGestures: false,
  card: TextMatchCard,
  property: TextMatchCardProperty,
};
