import React, { TextareaHTMLAttributes, useRef } from "react";
import CanvasDraw from "react-canvas-draw";

interface StandardCardSignatureProps
  extends Partial<
    Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "onChange">
  > {
  height: string | number;
  onChange?: (state: string) => void;
}

export const StandardCardSignature = React.forwardRef(function (
  props: StandardCardSignatureProps,
  _
) {
  const canvasRef = useRef<CanvasDraw | null>(null);

  return (
    <div className="relative w-full m-h-[174px] h-full border border-white rounded-3xl overflow-hidden flex items-center justify-center p-4">
      <CanvasDraw
        ref={canvasRef}
        canvasHeight={props.height}
        backgroundColor="transparent"
        hideGrid
        brushRadius={2}
        lazyRadius={0}
        brushColor="#fff"
        catenaryColor="#fff"
        onChange={(canvas) => props.onChange?.(canvas.getDataURL())}
      />
      <div className="absolute left-0 top-0 w-full h-full bg-white opacity-10 pointer-events-none"></div>
    </div>
  );
});
