import { StandardCardUploadFile } from "../../card_form";
import { ReactComponent as ImagePlaceholder } from "@/assets/vectors/icn_image_placeholder.svg";
import { StandardForm, StandardFormField } from "../../form";
import { CardProperties } from "../card_schema";
import clsx from "clsx";
import React from "react";
import { CardType } from "@/generated/protos/element_pb";
import { useForm } from "react-hook-form";

interface FileInputCardProps extends CardProperties {}

export const FileInputCard = React.forwardRef(function (
  props: Partial<FileInputCardProps>,
  _
) {
  const form = useForm({
    values: props[CardType.CARD_TYPE_FILE_INPUT] || {},
  });

  const label = form.getValues("label");
  const supportingText = form.getValues("supportingText");
  const supportedFiles = form.getValues("supportedFiles");

  return (
    <div className="px-4 py-[46px] flex flex-col">
      <span className="text-[32px] font-bold leading-9 text-white">
        {label || "Upload file"}
      </span>
      <span className="text-white text-lg leading-7 my-4">
        {supportingText ||
          "Welcome to our new hire onboarding program! This comprehensive journey is."}
      </span>

      <div className="my-8">
        <StandardForm form={form}>
          <div className="flex items-center gap-6">
            <StandardFormField<string> name={""}>
              {(props) => (
                <div
                  className={clsx("w-full h-[174px] relative", {
                    "pointer-events-none": true,
                    "cursor-not-allowed": true,
                  })}
                >
                  <StandardCardUploadFile
                    accept={supportedFiles || []}
                    {...props}
                  >
                    <>
                      <div className="w-full h-full flex flex-col items-center justify-center border-white border rounded-3xl border-dashed">
                        <ImagePlaceholder className="w-4 h-4 stroke-white" />
                        <span className="text-[10px] mt-2 leading-3 text-white">
                          Upload a file
                        </span>
                      </div>
                      <div className="absolute left-0 top-0 bg-white w-full h-full opacity-10"></div>
                    </>
                  </StandardCardUploadFile>
                </div>
              )}
            </StandardFormField>
          </div>
        </StandardForm>
      </div>
    </div>
  );
});
