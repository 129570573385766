import { useControllableState } from "@/common/use_controllable_state";
import clsx from "clsx";
import React from "react";
import { ReactComponent as RadioUnSelected } from "@/assets/vectors/icn_radio.svg";
import { ReactComponent as RadioSelected } from "@/assets/vectors/icn_radio_selected.svg";

interface StandardRaidoGroupOption {
  label: string;
  value: string | number;
}

interface StandardRaidoGroupProps {
  defaultValue?: string | number;
  value?: string | number;
  options: StandardRaidoGroupOption[];
  direction: "horizontal" | "vertical";
  onChange?: (state: string | number) => void;
}

export const StandardRaidoGroup = React.forwardRef(function (
  props: StandardRaidoGroupProps,
  _
) {
  const [value, setValue] = useControllableState({
    defaultProp: props.defaultValue,
    prop: props.value,
    onChange: props.onChange,
  });

  const onChange = (option: StandardRaidoGroupOption) => {
    if (value === option.value) return;

    setValue(option.value);
  };

  return (
    <div
      className={clsx("flex", "gap-4", {
        "flex-col": props.direction === "vertical",
        "flex-row": props.direction === "horizontal",
      })}
    >
      {props.options.map((option) => (
        <div
          key={option.value}
          className="cursor-pointer flex items-center"
          onClick={() => onChange(option)}
        >
          {value === option.value ? (
            <RadioSelected className=" w-4" />
          ) : (
            <RadioUnSelected className="stroke-primary-black opacity-50 w-4" />
          )}

          <span className="text-sm text-primary-black ml-4 leading-[18px]">
            {option.label}
          </span>
        </div>
      ))}
    </div>
  );
});
