import { ReactComponent as Location } from "@/assets/vectors/icn_location.svg";
import { AddressCardProperty } from "./property";
import { AddressCard } from "./card";
import { CardSchema } from "../card_schema";
import { CardType } from "@/generated/protos/element_pb";

export interface AddressCardProperties {
  label: string;
  supportingText: string;
  address: number[];
  required: boolean;
}

export const addressOptions = [
  { label: "Address Line 1", value: 0 },
  { label: "Address Line 2", value: 1 },
  { label: "City", value: 2 },
  { label: "State", value: 3 },
  { label: "Zip", value: 4 },
  { label: "Country", value: 5 },
];

export const addressCardConfig: CardSchema = {
  id: "",
  type: CardType.CARD_TYPE_ADDRESS,
  label: "Address",
  icon: Location,
  properties: {
    [CardType.CARD_TYPE_ADDRESS]: {
      label: "",
      supportingText: "",
      address: [0, 1, 2, 3, 4],
      required: true,
    },
  },
  enabledGestures: false,
  card: AddressCard,
  property: AddressCardProperty,
};
