import { ReactComponent as FreeResponse } from "@/assets/vectors/icn_free_response.svg";
import { FreeResponseCardProperty } from "./property";
import { FreeResponseCard } from "./card";
import { CardSchema } from "../card_schema";
import { CardType } from "@/generated/protos/element_pb";

export interface FreeResponseCardProperties {
  label: string;
  supportingText: string;
  charactersRange: number[];
  placeholder: string;
  required: boolean;
}

export const freeResponseCardConfig: CardSchema = {
  id: "",
  type: CardType.CARD_TYPE_FREE_RESPONSE,
  label: "Free response",
  icon: FreeResponse,
  properties: {
    [CardType.CARD_TYPE_FREE_RESPONSE]: {
      label: "",
      supportingText: "",
      placeholder: "",
      charactersRange: [0, 500],
      required: true,
    },
  },
  enabledGestures: false,
  card: FreeResponseCard,
  property: FreeResponseCardProperty,
};
