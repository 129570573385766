import { toastAtom, ToastType } from "@/stores";
import clsx from "clsx";
import { useAtom } from "jotai";

export function StandardToast() {
  const [value] = useAtom(toastAtom);

  return (
    <div className="toast toast-top toast-end z-50">
      {value.map((toast, index) => (
        <div
          className={clsx(
            "alert",
            "w-[250px]",
            "whitespace-normal",
            "break-all",
            {
              "alert-error": toast.type === ToastType.error,
              "alert-warning": toast.type === ToastType.warning,
              "alert-info": toast.type === ToastType.info,
              "alert-success": toast.type === ToastType.success,
            }
          )}
          key={index}
        >
          {toast.message}
        </div>
      ))}
    </div>
  );
}
