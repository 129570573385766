import { ReactComponent as Ahead } from "@/assets/vectors/icn_ahead.svg";
import { StandardLoadingIndicator } from "@/common";
import { useListCourses } from "@/hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Add } from "@/assets/vectors/icn_add.svg";
import { DateTime } from "luxon";

export function AllCoursesPage() {
  const { ListCourses, pending, courses } = useListCourses();

  useEffect(() => {
    ListCourses();
  }, []);

  const navigate = useNavigate();
  return (
    <div className="w-[1200px] py-4">
      {pending && <StandardLoadingIndicator />}
      {courses.map((course) => (
        <div
          className="flex justify-between items-center py-5 border-b border-silver-mist cursor-pointer last-of-type:border-none hover:opacity-50 transition-all duration-300"
          key={course.id}
          onClick={() => navigate(`/course-detail/${course.id}`)}
        >
          <div className="flex items-center">
            <img
              className="w-[88px] h-[48px] rounded-lg object-cover"
              src={`/api/v1/files/${course.cover}`}
            />
            <div className="flex flex-col ml-8 gap-1">
              <span className="leading-5 text-black font-semibold">
                {course.courseName}
              </span>
              <span className="text-xs leading-3 opacity-50">
                {DateTime.fromMillis(course.created!)
                  .setLocale("en")
                  .toRelative()}
              </span>
            </div>
          </div>
          <Ahead className="w-6 h-6 stroke-primary-black opacity-50" />
        </div>
      ))}
      <div className="flex items-center w-full justify-center h-[86px]">
        <Add className="stroke-primary-black w-4 h-4" />
        <span
          className="text-xs ml-4 cursor-pointer"
          onClick={() => navigate("/new-course")}
        >
          Add New
        </span>
      </div>
    </div>
  );
}
