import {
  HTMLAttributes,
  SelectHTMLAttributes,
  TextareaHTMLAttributes,
  useContext,
  useMemo,
} from "react";
import { FormContext } from "./standard_form";
import React from "react";
import { composeEventHandlers } from "@/common/compose_event_handlers";
import { RegisterOptions } from "react-hook-form";

interface StandardFormFieldRenderProps<T>
  extends Omit<
    HTMLAttributes<HTMLInputElement> &
      TextareaHTMLAttributes<HTMLTextAreaElement> &
      SelectHTMLAttributes<HTMLSelectElement>,
    "onChange" | "defaultValue" | "value"
  > {
  defaultValue?: T;
  value?: T;
  onChange?: (state: T) => void;
}

interface StandardFormFieldProps<T> {
  children:
    | React.ReactElement
    | ((props: StandardFormFieldRenderProps<T>) => React.ReactElement);
  label?: string;
  name: string;
  description?: string;
  registerOptions?: RegisterOptions;
}

export function StandardFormField<T>(props: StandardFormFieldProps<T>) {
  const context = useContext(FormContext);

  const errorMsg = context.form.formState.errors[props.name]?.message as string;

  const registerConfig = context.form.register(
    props.name,
    props.registerOptions
  );

  const onChange = composeEventHandlers(
    (nextValue) => {
      context?.form.setValue?.(props.name, nextValue);
      context?.form.trigger?.(props.name);
    },
    () => context.form.clearErrors(props.name)
  );

  const nextChildren = useMemo(() => {
    return typeof props.children === "function"
      ? props.children({
          defaultValue: context?.form.getValues?.(props.name),
          ...registerConfig,
          onChange,
        })
      : React.cloneElement(props.children, {
          ...props.children.props,
          defaultValue: [null, undefined].includes(
            context?.form.getValues?.(props.name)
          )
            ? props.children.props.defaultValue
            : context?.form.getValues?.(props.name),
          ...registerConfig,
          onChange,
        });
  }, [props]);

  return (
    <div className="flex flex-col w-full">
      {props.label && (
        <span className="font-bold text-xs leading-4 mb-1">{props.label}</span>
      )}
      {props.description && (
        <span className="font-medium leading-4 text-xs mb-3 opacity-50">
          {props.description}
        </span>
      )}
      {props.label && !props.description && <div className="h-1 w-full" />}
      {nextChildren}
      {errorMsg && (
        <span className="mt-1 text-xs text-red-800 font-semibold">
          {errorMsg}
        </span>
      )}
    </div>
  );
}
