import { useControllableState } from "@/common/use_controllable_state";
import clsx from "clsx";
import React, { LegacyRef, TextareaHTMLAttributes } from "react";

interface StandardTextareaProps
  extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "onChange"> {
  defaultValue?: string;
  value?: string;
  onChange?: (state: string) => void;
}

export const StandardTextarea = React.forwardRef(function (
  props: StandardTextareaProps,
  ref: LegacyRef<HTMLTextAreaElement>
) {
  const [value, setValue] = useControllableState({
    defaultProp: props.defaultValue,
    prop: props.value,
    onChange: props.onChange,
  });

  return (
    <div
      className={clsx(
        "w-full min-h-[108px] h-full h p-4 border border-black border-opacity-10 rounded-2xl"
      )}
    >
      <textarea
        {...props}
        ref={ref}
        defaultValue={value}
        className="w-full min-h-[108px] outline-none flex-1 placeholder:text-primary-black placeholder:opacity-50 text-sm"
        onChange={(event) =>
          setValue((event.target as HTMLTextAreaElement).value)
        }
      />
    </div>
  );
});
