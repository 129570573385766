import { useControllableState } from "@/common/use_controllable_state";
import clsx from "clsx";
import React, { useState } from "react";
import { InputHTMLAttributes } from "react";
import { HexAlphaColorPicker } from "react-colorful";

interface StandardColorPickerProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  defaultValue?: string;
  value?: string;
  children: React.ReactElement;
  onChange?: (state: string) => void;
}
export const StandardColorPicker = React.forwardRef(function (
  props: StandardColorPickerProps,
  _
) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useControllableState({
    defaultProp: props.defaultValue,
    prop: props.value,
    onChange: props.onChange,
  });

  return (
    <div className="relative">
      <div
        className={clsx(
          "absolute bottom-[40px] left-0 transition-all duration-300",
          {
            "opacity-0 -z-[10]": !open,
            "opacity-100 z-[20]": open,
          }
        )}
        onClick={() => {}}
      >
        <HexAlphaColorPicker
          color={value}
          onChange={(color) => setValue(color)}
        />
      </div>
      <div
        className={clsx("fixed left-0 top-0 right-0 bottom-0", {
          "-z-[10]": !open,
          "z-[10]": open,
        })}
        onClick={() => setOpen(false)}
      />
      {React.cloneElement(props.children, {
        ...props.children.props,
        className: clsx(props.children.props.className, "cursor-pointer"),
        onClick: () => setOpen(true),
      })}
    </div>
  );
});
