import { StandardForm, StandardFormField } from "../../form";
import { CardProperties } from "../card_schema";
import { StandardCardRating } from "../../card_form";
import { CardType } from "@/generated/protos/element_pb";
import { useForm } from "react-hook-form";

interface RatingCardProps extends CardProperties {}

export function RatingCard(props: Partial<RatingCardProps>) {
  const form = useForm({
    values: props[CardType.CARD_TYPE_RATING] || {},
  });

  const label = form.getValues("label");
  const supportingText = form.getValues("supportingText");

  const onContinue = async () => {
    await form.trigger();
    if (!Object.keys(form.formState.errors).length) {
      props.onContinue?.({
        [CardType.CARD_TYPE_PHONE_NUMBER]: form.getValues(),
      });
    }
  };

  return (
    <div className="px-4 py-[46px] flex flex-col">
      <span className="text-[32px] font-bold leading-9 text-white text-center">
        {label || "Title of Form"}
      </span>
      <span className="text-white text-lg leading-7 my-4 text-center">
        {supportingText ||
          "Welcome to our new hire onboarding program! This comprehensive journeyis."}
      </span>

      <div className="my-8">
        <StandardForm form={form} onFormChange={onContinue}>
          <div className="flex items-center gap-6">
            <StandardFormField<string>
              name="rating"
              registerOptions={{ required: "This field is required" }}
            >
              <StandardCardRating
                count={props[CardType.CARD_TYPE_RATING]?.count!}
                disabled={props.disabled}
              />
            </StandardFormField>
          </div>
        </StandardForm>
      </div>
    </div>
  );
}
