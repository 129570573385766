import { ReactComponent as Image } from "@/assets/vectors/icn_image.svg";
import { PhotoCard } from "./card";
import { PhotoCardProperty } from "./property";
import { CardSchema } from "../card_schema";
import { CardType } from "@/generated/protos/element_pb";

export interface PhotoCardProperties {
  supportingText: string;
  pageName: string;
  resizing: number;
  image: string;
}

export const resizingOptions = [
  {
    label: "Fixed",
    value: 0,
  },
  {
    label: "Filled",
    value: 1,
  },
];

export const phototCardConfig: CardSchema = {
  id: "",
  type: CardType.CARD_TYPE_PHOTO,
  label: "Photo",
  icon: Image,
  properties: {
    [CardType.CARD_TYPE_PHOTO]: {
      pageName: "",
      supportingText: "",
      resizing: 0,
      image: "",
    },
  },
  enabledGestures: true,
  card: PhotoCard,
  property: PhotoCardProperty,
};
