import { CancelToken } from "axios";

import { ApiClient } from "./api_client";
import {
  BatchEditCardSortRequest,
  BatchEditCardSortResponse,
  Card,
  CreateCardByCourseIdRequest,
  CreateCardByCourseIdResponse,
  DeleteCardByIdRequest,
  DeleteCardByIdResponse,
  EditCardByIdRequest,
  EditCardByIdResponse,
  ListCardByCourseIdRequest,
  ListCardByCourseIdResponse,
} from "@/generated/protos/card_pb";
import { DeepPartial } from "react-hook-form";

export class CardService {
  private constructor() {}

  public static async CreateCardByCourseId(
    payload: DeepPartial<CreateCardByCourseIdRequest.AsObject>,
    cancelToken?: CancelToken
  ) {
    const { courseId } = payload;

    const request = new CreateCardByCourseIdRequest();

    if (courseId) {
      request.setCourseId(courseId);
    }

    const card = new Card();

    if (payload.card) {
      card.setId(payload.card.id!);
      card.setType(payload.card.type!);
      card.setProperties(payload.card.properties!);
    }

    request.setCard(card);

    const bytes = await ApiClient.getInstance().postProtoMessage({
      url: "api/v1/card/createByCourseId",
      message: request,
      cancelToken,
    });

    const { card: nextCard } =
      CreateCardByCourseIdResponse.deserializeBinary(bytes).toObject();

    return nextCard;
  }

  public static async EditCardById(
    payload: DeepPartial<EditCardByIdRequest.AsObject>,
    cancelToken?: CancelToken
  ) {
    const request = new EditCardByIdRequest();
    const card = new Card();

    if (payload.card) {
      card.setId(payload.card.id!);
      card.setType(payload.card.type!);
      card.setProperties(payload.card.properties!);
    }

    request.setCard(card);

    const bytes = await ApiClient.getInstance().postProtoMessage({
      url: "api/v1/card/editById",
      message: request,
      cancelToken,
    });

    return EditCardByIdResponse.deserializeBinary(bytes).toObject();
  }

  public static async DeleteCardById(
    payload: DeleteCardByIdRequest.AsObject,
    cancelToken?: CancelToken
  ) {
    const request = new DeleteCardByIdRequest();
    request.setId(payload.id);

    const bytes = await ApiClient.getInstance().postProtoMessage({
      url: "api/v1/card/deleteById",
      message: request,
      cancelToken,
    });

    return DeleteCardByIdResponse.deserializeBinary(bytes).toObject();
  }

  public static async ListCardByCourseId(
    payload: ListCardByCourseIdRequest.AsObject,
    cancelToken?: CancelToken
  ) {
    const request = new ListCardByCourseIdRequest();
    request.setCourseId(payload.courseId);

    const bytes = await ApiClient.getInstance().postProtoMessage({
      url: "api/v1/card/listByCourseId",
      message: request,
      cancelToken,
    });

    const { cardsList } =
      ListCardByCourseIdResponse.deserializeBinary(bytes).toObject();

    return cardsList;
  }

  public static async BatchEditSort(
    payload: DeepPartial<BatchEditCardSortRequest.AsObject>,
    cancelToken?: CancelToken
  ) {
    const request = new BatchEditCardSortRequest();

    if (payload.cardsList) {
      const cards = payload.cardsList.map((item) => {
        const card = new Card();
        card.setId(item?.id!);
        card.setSort(item?.sort!);
        return card;
      });
      request.setCardsList(cards);
    }

    const bytes = await ApiClient.getInstance().postProtoMessage({
      url: "api/v1/card/batchEditSort",
      message: request,
      cancelToken,
    });

    return BatchEditCardSortResponse.deserializeBinary(bytes).toObject();
  }
}
