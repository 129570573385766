import clsx from "clsx";
import { HTMLAttributes, useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ReactComponent as Video } from "@/assets/vectors/icn_video.svg";
import ReactPlayer from "react-player";
import { StandardInput } from "./form";
import { debounce } from "lodash";
import { FileService } from "@/services";
import { useControllableState } from "../use_controllable_state";

interface StandardUploadVideoProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "onChange"> {
  accept: string[];
  defaultValue?: string;
  value?: string;
  onChange?: (state: string) => void;
}

export function StandardUploadVideo(props: StandardUploadVideoProps) {
  const [loading, setLoading] = useState(true);

  const [video, setVideo] = useControllableState({
    prop: props.value,
    defaultProp: props.defaultValue,
    onChange: props.onChange,
  });

  const onEmbedVideoUrlChange = debounce((url: string) => {
    setLoading(true);
    setVideo(url);
  }, 500);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("file", acceptedFiles[0]);

        const uuid = await FileService.uploadFile(formData);

        setVideo(uuid);
      } finally {
        setLoading(false);
      }
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "video/*": [],
    },
    maxFiles: 1,
    maxSize: 1024 * 1024 * 50,
  });

  const videoUrl = useMemo(() => {
    if (!video) return "";

    if (video.includes("https")) {
      return video;
    }

    return `/api/v1/files/${video}`;
  }, [video]);

  return (
    <div className="w-full h-full">
      <div className="w-full h-[180px] rounded-2xl overflow-hidden relative">
        {videoUrl ? (
          <>
            {loading && (
              <div className="skeleton w-full h-full absolute left-0 top-0 z-[2]" />
            )}
            <div className="w-full h-full relative">
              <ReactPlayer
                url={videoUrl}
                height="100%"
                width="100%"
                style={{
                  objectFit: "contain",
                }}
                controls={true}
                onReady={() => setLoading(false)}
              />
            </div>
          </>
        ) : (
          <div
            {...getRootProps()}
            className={clsx(
              "w-full h-full rounded-2xl flex items-center justify-center cursor-pointer border border-dashed border-black border-opacity-10 transition-all",
              {
                "opacity-50": isDragActive,
              }
            )}
          >
            <input {...getInputProps()} />

            <div className="flex flex-col items-center">
              <Video className="w-4 h-4 opacity-50 stroke-primary-black" />
              <span className="text-[10px] opacity-50 mt-2 leading-3">
                Upload a video
              </span>
            </div>
          </div>
        )}
      </div>

      <div className="flex items-center mt-4">
        <span className="opacity-50 text-xs flex-shrink-0">Or embed from</span>

        <div className="h-[36px] w-full ml-4">
          <StandardInput
            defaultValue={video?.includes("http") ? video : ""}
            placeholder="ex. https://www.youtube.com"
            onChange={onEmbedVideoUrlChange}
          />
        </div>
      </div>
    </div>
  );
}
