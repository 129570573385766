import { Outlet } from "react-router-dom";
import { DashboardNavigator } from "./dashboard_navigator";

export function DashboardRoot() {
  return (
    <div className="flex flex-col items-center">
      <DashboardNavigator />

      <div className="max-w-[1200px] flex flex-col items-center">
        <Outlet />
      </div>
    </div>
  );
}
