import clsx from "clsx";
import React from "react";
import { HTMLAttributes, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useControllableState } from "../use_controllable_state";
import { FileService } from "@/services/index";
import { useAtom } from "jotai";
import { pleaseWaitAtom } from "@/stores";

interface StandardUploadImageProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "onChange"> {
  accept: string[];
  defaultValue?: string;
  value?: string;
  disabledPreview?: boolean;
  onChange?: (state: string) => void;
}

export const StandardUploadImage = React.forwardRef(function (
  props: StandardUploadImageProps,
  _
) {
  const [__, setPleaseWait] = useAtom(pleaseWaitAtom);
  const [value, setValue] = useControllableState({
    defaultProp: props.defaultValue,
    prop: props.defaultValue,
    onChange: props.onChange,
  });

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      try {
        setPleaseWait(true);
        const formData = new FormData();
        formData.append("file", acceptedFiles[0]);

        const uuid = await FileService.uploadFile(formData);
        setValue(uuid);
      } finally {
        setPleaseWait(false);
      }
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": props.accept,
    },
    maxFiles: 1,
    maxSize: 1024 * 1024 * 5,
  });

  return (
    <div
      {...getRootProps()}
      className={clsx(
        "w-full h-full flex items-center justify-center cursor-pointer transition-all overflow-hidden",
        {
          "opacity-50": isDragActive,
        },
        props.className
      )}
    >
      {!value || props.disabledPreview ? (
        <>
          <input {...getInputProps()} />

          {props.children}
        </>
      ) : (
        <div className="w-full h-full relative">
          <img
            src={`/api/v1/files/${value}`}
            className="w-full h-full object-cover"
          />
        </div>
      )}
    </div>
  );
});
