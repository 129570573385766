import { CardType, CardTypeMap } from "@/generated/protos/element_pb";
import { addressCardConfig } from "./address_card";
import { emailCardConfig } from "./email_card";
import { fileInputCardConfig } from "./file_input_card";
import { freeResponseCardConfig } from "./free_response_card";
import { nameCardConfig } from "./name_card";
import { phoneNumberCardConfig } from "./phone_number_card";
import { phototCardConfig } from "./photo_card";
import { ratingCardConfig } from "./rating_card";
import { signatureCardConfig } from "./signature_card";
import { textMatchCardConfig } from "./text_match_card";
import { videoCardConfig } from "./video_card";
import { CardSchema } from "./card_schema";

export const cardMap: {
  [K in CardTypeMap[keyof CardTypeMap]]: CardSchema;
} = {
  [CardType.CARD_TYPE_NAME]: nameCardConfig,
  [CardType.CARD_TYPE_FREE_RESPONSE]: freeResponseCardConfig,
  [CardType.CARD_TYPE_ADDRESS]: addressCardConfig,
  [CardType.CARD_TYPE_EMAIL]: emailCardConfig,
  [CardType.CARD_TYPE_VIDEO]: videoCardConfig,
  [CardType.CARD_TYPE_PHONE_NUMBER]: phoneNumberCardConfig,
  [CardType.CARD_TYPE_RATING]: ratingCardConfig,
  [CardType.CARD_TYPE_SIGNATURE]: signatureCardConfig,
  [CardType.CARD_TYPE_TEXT_MATCH]: textMatchCardConfig,
  [CardType.CARD_TYPE_FILE_INPUT]: fileInputCardConfig,
  [CardType.CARD_TYPE_PHOTO]: phototCardConfig,
};
