import { CardType } from "@/generated/protos/element_pb";
import {
  StandardForm,
  StandardFormField,
  StandardInput,
  StandardRandge,
  StandardToogle,
} from "../../form";
import { CardProperties } from "../card_schema";
import { useForm } from "react-hook-form";

interface FreeResponseCardPropertyProps extends Partial<CardProperties> {}

export const FreeResponseCardProperty = (
  props: FreeResponseCardPropertyProps
) => {
  const form = useForm({
    defaultValues: props[CardType.CARD_TYPE_FREE_RESPONSE],
  });
  return (
    <StandardForm
      form={form}
      onFormChange={(value) =>
        props.onChange?.({ [CardType.CARD_TYPE_FREE_RESPONSE]: value })
      }
    >
      <div className="flex flex-col gap-8">
        <StandardFormField<string> name="label" label="Label">
          {(props) => (
            <div className="h-9">
              <StandardInput {...props} placeholder="ex. Short Answer" />
            </div>
          )}
        </StandardFormField>

        <StandardFormField<string>
          name="supportingText"
          label="Supporting Text"
        >
          {(props) => (
            <div className="h-9">
              <StandardInput
                {...props}
                placeholder="ex. This is supporting text"
              />
            </div>
          )}
        </StandardFormField>

        <StandardFormField<string> name="placeholder" label="Placeholder">
          {(props) => (
            <div className="h-9">
              <StandardInput placeholder="ex. Placeholder" {...props} />
            </div>
          )}
        </StandardFormField>

        <StandardFormField<number[]>
          name="charactersRange"
          label="Advanced Options"
          description="Choose how many characters a user can is required to input."
        >
          {(props) => (
            <div className="h-9">
              <StandardRandge
                {...props}
                renderRightPrefix={["min characters", "max characters"].map(
                  (text) => (
                    <span className="text-sm opacity-50 text-nowrap">
                      {text}
                    </span>
                  )
                )}
              />
            </div>
          )}
        </StandardFormField>

        <StandardFormField
          name="required"
          label="Required?"
          description="Will require user to respond to continue"
        >
          <StandardToogle />
        </StandardFormField>
      </div>
    </StandardForm>
  );
};
