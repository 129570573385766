import React from "react";
import { StandardRandge } from "./standard_range";
import { useControllableState } from "@/common/use_controllable_state";

interface StandardFileSizeLimitProps {
  value?: number[][];
  defaultValue?: number[][];
  onChange?: (state: number[][]) => void;
}

export const StandardFileSizeLimit = React.forwardRef(function (
  props: StandardFileSizeLimitProps,
  _
) {
  const [value, setValue] = useControllableState({
    defaultProp: props.defaultValue,
    prop: props.value,
    onChange: props.onChange,
  });

  return (
    <div className="flex flex-col gap-4">
      <StandardRandge
        unit="KB"
        defaultValue={value?.[0] || []}
        renderRightPrefix={[
          <span className="opacity-50 text-nowrap">min size</span>,
          <span className="opacity-50 text-nowrap">max size</span>,
        ]}
        onChange={(nextValue) => setValue([nextValue, value?.[1] ?? []])}
      />

      <StandardRandge
        defaultValue={value?.[1] || []}
        renderRightPrefix={[
          <span className="opacity-50 text-nowrap">min characters</span>,
          <span className="opacity-50 text-nowrap">max characters</span>,
        ]}
        onChange={(nextValue) => setValue([value?.[0] ?? [], nextValue])}
      />
    </div>
  );
});
