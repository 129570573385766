import { CardType } from "@/generated/protos/element_pb";
import { resizingOptions } from ".";
import {
  StandardForm,
  StandardFormField,
  StandardInput,
  StandardRaidoGroup,
} from "../../form";
import { StandardUploadImage } from "../../standard_upload_image";
import { CardProperties } from "../card_schema";
import { ReactComponent as ImagePlaceholder } from "@/assets/vectors/icn_image_placeholder.svg";
import { useForm } from "react-hook-form";

interface PhotoCardPropertyProps extends Partial<CardProperties> {}

export const PhotoCardProperty = (props: PhotoCardPropertyProps) => {
  const form = useForm({
    defaultValues: props[CardType.CARD_TYPE_PHOTO],
  });
  return (
    <StandardForm
      form={form}
      onFormChange={(value) =>
        props.onChange?.({
          [CardType.CARD_TYPE_PHOTO]: value,
        })
      }
    >
      <div className="flex flex-col gap-8">
        <StandardFormField<string> name="pageName" label="Page Name">
          {(props) => (
            <div className="h-9">
              <StandardInput {...props} placeholder="ex. Image" />
            </div>
          )}
        </StandardFormField>

        <StandardFormField<string> name="image" label="Upload Image">
          {(props) => (
            <div className="h-[160px] w-full">
              <div className="w-full h-full flex flex-col items-center justify-center border border-black border-opacity-10 border-dashed rounded-2xl overflow-hidden">
                <StandardUploadImage accept={[]} {...props}>
                  <div className="flex flex-col items-center">
                    <ImagePlaceholder className="w-4 h-4 opacity-50 stroke-primary-black" />
                    <span className="text-[10px] opacity-50 mt-2 leading-3">
                      Upload an image
                    </span>
                  </div>
                </StandardUploadImage>
              </div>
            </div>
          )}
        </StandardFormField>

        <StandardFormField<number>
          name="resizing"
          label="Resizing"
          description="How should we resize for different screen sizes."
        >
          <StandardRaidoGroup options={resizingOptions} direction="vertical" />
        </StandardFormField>
      </div>
    </StandardForm>
  );
};
