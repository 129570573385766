import { courseDetailAtom, coursesAtom } from "@/stores/course_atom";
import { useAtom } from "jotai";
import { CourseService } from "@/services";
import {
  CreateCoursesRequest,
  DeleteCourseByIdRequest,
  EditCourseByIdRequest,
  GetCoursesByIdRequest,
} from "@/generated/protos/courses_pb";
import { useState } from "react";
import { DeepPartial } from "react-hook-form";

export function useListCourses() {
  const [courses, setCourses] = useAtom(coursesAtom);
  const [pending, setPending] = useState(false);

  async function ListCourses() {
    try {
      if (!pending) {
        setPending(true);
        const nextCourses = await CourseService.ListCourses();
        setCourses(nextCourses);
        return nextCourses;
      }
    } finally {
      setPending(false);
    }
  }

  return {
    pending,
    courses,
    ListCourses,
  };
}

export function useGetCoursesById() {
  const [courseDetail, setCourseDetail] = useAtom(courseDetailAtom);
  const [pending, setPending] = useState(false);

  async function GetCoursesById(params: GetCoursesByIdRequest.AsObject) {
    try {
      if (!pending) {
        if (courseDetail?.id !== params.id) {
          setCourseDetail(undefined);
        }

        setPending(true);
        const nextCourse = await CourseService.GetCoursesById(params);
        setCourseDetail(nextCourse);
        return nextCourse;
      }
    } finally {
      setPending(false);
    }
  }

  return {
    pending,
    courseDetail,
    GetCoursesById,
  };
}

export function useCreateCourse() {
  async function CreateCourses(
    payload: DeepPartial<CreateCoursesRequest.AsObject>
  ) {
    return await CourseService.CreateCourses(payload);
  }

  return {
    CreateCourses,
  };
}

export function useEditCourseById() {
  async function EditCourseById(
    payload: DeepPartial<EditCourseByIdRequest.AsObject>
  ) {
    return await CourseService.EditCourseById(payload);
  }

  return {
    EditCourseById,
  };
}

export function useDeleteCourseById() {
  async function DeleteCourseById(
    payload: DeepPartial<DeleteCourseByIdRequest.AsObject>
  ) {
    return await CourseService.DeleteCourseById(payload);
  }

  return {
    DeleteCourseById,
  };
}
