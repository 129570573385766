import clsx from "clsx";
import { CardProperties } from "../card_schema";
import { CardType } from "@/generated/protos/element_pb";

interface PhotoCardProps extends CardProperties {}

export function PhotoCard(props: Partial<PhotoCardProps>) {
  const { resizing, image } = props[CardType.CARD_TYPE_PHOTO] || {};

  return (
    <div className="flex flex-1 items-center justify-center flex-col">
      {image ? (
        <img
          className={clsx(
            "w-full rounded-2xl object-cover transition-all duration-500",
            {
              "flex-1": resizing === 1,
              "h-1/2": resizing === 0,
            }
          )}
          src={`/api/v1/files/${image}`}
        />
      ) : (
        <div
          className={clsx("skeleton w-full rounded-2xl transition-all", {
            "h-full": resizing === 1,
            "h-1/2": resizing === 0,
          })}
        />
      )}
    </div>
  );
}
