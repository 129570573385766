import clsx from "clsx";
import { StandardDropdownMenuType } from "./dropdown_menu_type";

export interface DropdownMenuItemProps {
  type: StandardDropdownMenuType;
  label: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
  onClick?(prop: DropdownMenuItemProps): unknown;
}

export function DropdownMenuItem(props: DropdownMenuItemProps) {
  const Icon = props.icon;

  return (
    <li onClick={() => props.onClick?.(props)}>
      <a className="flex items-center justify-between">
        <span
          className={clsx("font-medium text-[13px]", {
            "text-primary-black": true,
            "text-secondary-red": props.type === StandardDropdownMenuType.error,
          })}
        >
          {props.label}
        </span>
        <Icon
          className={clsx("w-4", {
            "stroke-primary-black": true,
            "stroke-secondary-red":
              props.type === StandardDropdownMenuType.error,
          })}
        />
      </a>
    </li>
  );
}
