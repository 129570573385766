import { RouterProvider } from "react-router-dom";

import {
  StandardPleaseWait,
  StandardToast,
  StandardUnexpectedError,
} from "./common";

import { router } from "./router";

// import { DevTools } from "jotai-devtools";

export function Root() {
  return (
    <>
      {/* <DevTools /> */}
      <RouterProvider router={router} />
      <StandardPleaseWait />
      <StandardToast />
      <StandardUnexpectedError />
    </>
  );
}
