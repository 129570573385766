import ReactPlayer from "react-player";
import { CardProperties } from "../card_schema";
import { useMemo, useState } from "react";
import React from "react";
import { CardType } from "@/generated/protos/element_pb";

interface VideoCardProps extends CardProperties {}

export const VideoCard = React.forwardRef(function (
  props: Partial<VideoCardProps>,
  _
) {
  const [loading, setLoading] = useState(true);

  const formValue = props[CardType.CARD_TYPE_VIDEO];

  const videoUrl = useMemo(() => {
    const video = formValue?.video;

    if (!video) return "";

    if (video.includes("http")) {
      return video;
    }

    return `/api/v1/files/${video}`;
  }, [formValue?.video]);

  return (
    <div className="px-4 flex items-center justify-center relative -mt-6 flex-1">
      {loading && (
        <div className="skeleton w-full h-[420px] absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-[2]" />
      )}
      <div className="w-full h-[420px] rounded-2xl overflow-hidden relative">
        <ReactPlayer
          url={videoUrl}
          height="100%"
          width="100%"
          style={{
            objectFit: "contain",
          }}
          controls={true}
          onReady={() => setLoading(false)}
        />
      </div>
    </div>
  );
});
