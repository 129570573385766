import { CancelToken } from "axios";

import { ApiClient } from "./api_client";
import {
  Course,
  CreateCoursesRequest,
  CreateCoursesResponse,
  GetCoursesByIdRequest,
  GetCoursesByIdResponse,
  ListCoursesRequest,
  ListCoursesResponse,
  EditCourseByIdRequest,
  DeleteCourseByIdRequest,
} from "@/generated/protos/courses_pb";
import { DeepPartial } from "react-hook-form";

export class CourseService {
  private constructor() {}

  public static async CreateCourses(
    payload: DeepPartial<CreateCoursesRequest.AsObject>,
    cancelToken?: CancelToken
  ) {
    const request = new CreateCoursesRequest();
    const course = new Course();

    if (payload.course) {
      const {
        backgroundColor,
        courseName,
        cover,
        description,
        logo,
        textColor,
        secondaryColor,
      } = payload.course;

      course.setBackgroundColor(backgroundColor!);
      course.setCourseName(courseName!);
      course.setCover(cover!);
      course.setDescription(description!);
      course.setLogo(logo!);
      course.setTextColor(textColor!);
      course.setSecondaryColor(secondaryColor!);
    }

    request.setCourse(course);

    const bytes = await ApiClient.getInstance().postProtoMessage({
      url: "api/v1/courses/create",
      message: request,
      cancelToken,
    });

    return CreateCoursesResponse.deserializeBinary(bytes).toObject();
  }

  public static async GetCoursesById(
    payload: GetCoursesByIdRequest.AsObject,
    cancelToken?: CancelToken
  ) {
    const request = new GetCoursesByIdRequest();
    request.setId(payload.id);

    const bytes = await ApiClient.getInstance().postProtoMessage({
      url: "api/v1/courses/getById",
      message: request,
      cancelToken,
    });

    const { course } =
      GetCoursesByIdResponse.deserializeBinary(bytes).toObject();

    return course;
  }

  public static async ListCourses(cancelToken?: CancelToken) {
    const request = new ListCoursesRequest();

    const bytes = await ApiClient.getInstance().postProtoMessage({
      url: "api/v1/courses/list",
      message: request,
      cancelToken,
    });

    const { coursesList } =
      ListCoursesResponse.deserializeBinary(bytes).toObject();

    return coursesList || [];
  }

  public static async EditCourseById(
    payload: DeepPartial<EditCourseByIdRequest.AsObject>,
    cancelToken?: CancelToken
  ) {
    const request = new EditCourseByIdRequest();
    const course = new Course();

    if (payload.course) {
      const {
        backgroundColor,
        courseName,
        cover,
        description,
        logo,
        textColor,
        secondaryColor,
        id,
      } = payload.course;

      course.setId(id!);
      course.setBackgroundColor(backgroundColor!);
      course.setCourseName(courseName!);
      course.setCover(cover!);
      course.setDescription(description!);
      course.setLogo(logo!);
      course.setTextColor(textColor!);
      course.setSecondaryColor(secondaryColor!);
    }

    request.setCourse(course);

    return await ApiClient.getInstance().postProtoMessage({
      url: "api/v1/courses/editById",
      message: request,
      cancelToken,
    });
  }

  public static async DeleteCourseById(
    payload: DeepPartial<DeleteCourseByIdRequest.AsObject>,
    cancelToken?: CancelToken
  ) {
    const request = new DeleteCourseByIdRequest();
    request.setId(payload.id!);

    return await ApiClient.getInstance().postProtoMessage({
      url: "api/v1/courses/deleteById",
      message: request,
      cancelToken,
    });
  }
}
