import React, { HTMLAttributes } from "react";
import { useControllableState } from "../use_controllable_state";
import { StandardColorPicker } from "./standard_color_picker";

interface StandardThemeColorProps
  extends Omit<
    HTMLAttributes<HTMLDivElement>,
    "onChange" | "value" | "defaultValue"
  > {
  onChange?: (state: string[]) => void;
  defaultValue?: string[];
  value?: string[];
}

export const StandardThemeColor = React.forwardRef(function (
  props: StandardThemeColorProps,
  _
) {
  const [value, setValue] = useControllableState({
    defaultProp: props.defaultValue,
    prop: props.value,
    onChange: props.onChange,
  });

  return (
    <div className="flex flex-col gap-4">
      {["Background", "Secondary", "Text"].map((text, index) => (
        <div
          key={text}
          className="relative px-4 w-[270px] h-9 rounded-2xl flex justify-between items-center border border-black border-opacity-10"
        >
          <span className="text-sm opacity-50">{text}</span>
          <StandardColorPicker
            onChange={(color) => {
              const nextColors = [...value!];
              nextColors[index] = color;
              setValue(nextColors);
            }}
          >
            <div className="flex items-center text-sm cursor-pointer">
              <span>{value?.[index]}</span>
              <div
                className="w-3 h-3 rounded-[3px] ml-3"
                style={{ backgroundColor: value?.[index] }}
              />
            </div>
          </StandardColorPicker>
        </div>
      ))}
    </div>
  );
});
