import { pleaseWaitAtom } from "@/stores";
import clsx from "clsx";
import { useAtom } from "jotai";

export function StandardPleaseWait() {
  const [value] = useAtom(pleaseWaitAtom);

  return (
    <>
      <dialog className={clsx("modal", { "modal-open": value })}>
        <div className="modal-box w-fit text-base px-10 py-6" role="dialog">
          Please Wait...
        </div>
      </dialog>
    </>
  );
}
