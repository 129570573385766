import { ReactComponent as Rating } from "@/assets/vectors/icn_rating.svg";
import { RatingCardProperty } from "./property";
import { RatingCard } from "./card";
import { CardSchema } from "../card_schema";
import { CardType } from "@/generated/protos/element_pb";

export interface RatingCardProperties {
  label: string;
  supportingText: string;
  required: boolean;
  count: number;
  rating: number;
}

export const countOptions = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
];

export const ratingCardConfig: CardSchema = {
  id: "",
  type: CardType.CARD_TYPE_RATING,
  label: "Rating",
  icon: Rating,
  properties: {
    [CardType.CARD_TYPE_RATING]: {
      label: "",
      supportingText: "",
      required: true,
      count: 5,
      rating: 0,
    },
  },
  enabledGestures: false,
  card: RatingCard,
  property: RatingCardProperty,
};
