import { StandardCardInput } from "../../card_form";
import { StandardForm, StandardFormField } from "../../form";
import { ReactComponent as Ahead } from "@/assets/vectors/icn_ahead.svg";
import { CardProperties } from "../card_schema";
import clsx from "clsx";
import { CardType } from "@/generated/protos/element_pb";
import { useForm } from "react-hook-form";

interface AddressCardProps extends CardProperties {}

export function AddressCard(props: Partial<AddressCardProps>) {
  const form = useForm({
    values: props[CardType.CARD_TYPE_ADDRESS] || {},
  });

  const label = form.getValues("label");
  const supportingText = form.getValues("supportingText");
  const address = form.getValues("address");
  const showAddressLine1 = address?.includes(0);
  const showAddressLine2 = address?.includes(1);
  const showCity = address?.includes(2);
  const showState = address?.includes(3);
  const showZip = address?.includes(4);
  const showCountry = address?.includes(5);

  const onContinue = async () => {
    await form.trigger();
    if (!Object.keys(form.formState.errors).length) {
      props.onContinue?.({ [CardType.CARD_TYPE_ADDRESS]: form.getValues() });
    }
  };

  return (
    <div className="px-4 py-[46px] flex flex-col">
      <span className="text-[32px] font-bold leading-9 text-white">
        {label || "Your address"}
      </span>
      <span className="text-white text-lg leading-7 my-4">
        {supportingText ||
          "Welcome to our new hire onboarding program! This comprehensive journey is."}
      </span>

      <div className="my-8">
        <StandardForm form={form}>
          <div className="flex flex-col gap-6">
            {showAddressLine1 && (
              <StandardFormField<string>
                name="addressLine1"
                registerOptions={{ required: "This field is required" }}
              >
                {(formPorps) => (
                  <div className="h-14">
                    <StandardCardInput
                      {...formPorps}
                      placeholder="Address Line 1"
                    />
                  </div>
                )}
              </StandardFormField>
            )}

            {showAddressLine2 && (
              <StandardFormField<string>
                name="addressLine2"
                registerOptions={{ required: "This field is required" }}
              >
                {(formPorps) => (
                  <div className="h-14">
                    <StandardCardInput
                      {...formPorps}
                      placeholder="Address Line 2"
                    />
                  </div>
                )}
              </StandardFormField>
            )}

            {(showCity || showState) && (
              <div className="flex items-center gap-4">
                {showCity && (
                  <StandardFormField<string>
                    name="City"
                    registerOptions={{ required: "This field is required" }}
                  >
                    {(formPorps) => (
                      <div className="h-14">
                        <StandardCardInput {...formPorps} placeholder="City" />
                      </div>
                    )}
                  </StandardFormField>
                )}

                {showState && (
                  <StandardFormField<string>
                    name="State"
                    registerOptions={{ required: "This field is required" }}
                  >
                    {(formPorps) => (
                      <div className="h-14">
                        <StandardCardInput {...formPorps} placeholder="State" />
                      </div>
                    )}
                  </StandardFormField>
                )}
              </div>
            )}

            {showZip && (
              <StandardFormField<string> name="zip">
                {(formPorps) => (
                  <div className="h-14">
                    <StandardCardInput {...formPorps} placeholder="Zip" />
                  </div>
                )}
              </StandardFormField>
            )}

            {showCountry && (
              <StandardFormField<string> name="country">
                {(formPorps) => (
                  <div className="h-14">
                    <StandardCardInput {...formPorps} placeholder="country" />
                  </div>
                )}
              </StandardFormField>
            )}
          </div>
        </StandardForm>
      </div>

      <div
        className={clsx(
          "flex items-center bg-black w-[128px] h-10 rounded-3xl justify-center",
          {
            "hover:opacity-50 transition-all cursor-pointer": !props.disabled,
            "cursor-not-allowed pointer-events-none": props.disabled,
          }
        )}
        onClick={onContinue}
      >
        <span className="mr-2 text-white">Continue</span>
        <Ahead className="stroke-white w-6 h-6" />
      </div>
    </div>
  );
}
