import { ReactComponent as ArrowDown } from "@/assets/vectors/icn_arrow_down.svg";
import { useControllableState } from "@/common/use_controllable_state";
import React, { LegacyRef } from "react";
import { SelectHTMLAttributes } from "react";

interface StandardSelectOption {
  label: string;
  value: string | number;
}

interface StandardSelectProps
  extends Omit<SelectHTMLAttributes<HTMLSelectElement>, "onChange"> {
  onChange?: (state: string | number) => void;
  options?: StandardSelectOption[];
  defaultValue?: string | number;
  value?: string | number;
}

export const StandardSelect = React.forwardRef(function (
  props: StandardSelectProps,
  ref: LegacyRef<HTMLSelectElement>
) {
  const [value, setValue] = useControllableState({
    defaultProp: props.defaultValue,
    prop: props.value,
    onChange: props.onChange,
  });

  return (
    <div className="w-full h-full flex items-center border border-black border-opacity-10 rounded-2xl relative">
      <select
        ref={ref}
        {...props}
        defaultValue={value}
        className="w-full h-full px-4 appearance-none outline-none rounded-2xl text-sm cursor-pointer"
        onChange={(event) => setValue(event.target.value)}
      >
        <option value="" disabled hidden>
          Select an option
        </option>
        {(props.options || []).map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <ArrowDown className="w-4 h-4 stroke-black pointer-events-none absolute right-4 top-1/2 -translate-y-1/2" />
    </div>
  );
});
