import clsx from "clsx";
import { DropdownMenuItem, DropdownMenuItemProps } from "./dropdown_menu_item";

export enum StandardDropdownMenuPosition {
  top_start,
  top_end,
  left_start,
  left_end,
  bottom_start,
  bottom_end,
  right_start,
  right_end,
}

interface StandardDropdownMenuProps {
  options: DropdownMenuItemProps[][];
  children: React.ReactElement;
  position: StandardDropdownMenuPosition;
  offsetT?: number;
  offsetB?: number;
  offsetL?: number;
  offsetR?: number;
}

export function StandardDropdownMenu(props: StandardDropdownMenuProps) {
  if (!props.options?.flat()?.length) {
    throw new Error("Options cannot be empty");
  }

  return (
    <div
      className={clsx("dropdown", {
        "dropdown-top":
          props.position === StandardDropdownMenuPosition.top_start,
        "dropdown-top dropdown-end":
          props.position === StandardDropdownMenuPosition.top_end,
        "dropdown-left":
          props.position === StandardDropdownMenuPosition.left_start,
        "dropdown-left dropdown-end":
          props.position === StandardDropdownMenuPosition.left_end,
        "dropdown-bottom":
          props.position === StandardDropdownMenuPosition.bottom_start,
        "dropdown-bottom dropdown-end":
          props.position === StandardDropdownMenuPosition.bottom_end,
        "dropdown-right":
          props.position === StandardDropdownMenuPosition.right_start,
        "dropdown-right dropdown-end":
          props.position === StandardDropdownMenuPosition.right_end,
      })}
    >
      <div tabIndex={0} role="button">
        {props.children}
      </div>
      <ul
        tabIndex={0}
        className={clsx(
          "dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow"
        )}
        style={{
          marginLeft: props.offsetL,
          marginRight: props.offsetR,
          marginTop: props.offsetT,
          marginBottom: props.offsetB,
        }}
      >
        {props.options.map((option, index) => (
          <div key={index.toString()}>
            <div>
              {option.map((menu) => (
                <DropdownMenuItem key={menu.label} {...menu} />
              ))}
            </div>
            {index !== props.options.length - 1 ? (
              <div className="h-4 w-full flex items-center px-2">
                <div className="h-[1px] w-full bg-black bg-opacity-10" />
              </div>
            ) : null}
          </div>
        ))}
      </ul>
    </div>
  );
}
