import { ReactComponent as Figma } from "@/assets/vectors/icn_figma.svg";
import { ReactComponent as Notification } from "@/assets/vectors/icn_notification.svg";
import { ReactComponent as ArrowDown } from "@/assets/vectors/icn_arrow_down.svg";
import { useNavigate } from "react-router-dom";

export function DashboardNavigator() {
  const navigate = useNavigate();

  return (
    <div className="w-full flex justify-center px-[120px] sticky left-0 top-0 bg-white z-20">
      <div className="max-w-[1200px] flex justify-between items-center w-full h-16 text-sm">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => navigate("/")}
        >
          <Figma className="w-6" />
          <span className="ml-6 mr-1 font-medium">Figma</span>
          <span className="font-medium">Learning Hub</span>
        </div>

        <div className="flex items-center gap-6">
          <span className="opacity-50 cursor-pointer">Resources</span>
          <span className="opacity-50cursor-pointer ">Help</span>
          <div
            className="h-9 w-[78px] bg-primary-black rounded-full text-white flex items-center justify-center font-medium cursor-pointer hover:opacity-50 transition-all"
            onClick={() => navigate("/new-course")}
          >
            Create
          </div>
          <div className="relative cursor-pointer">
            <Notification className="stroke-primary-black w-4" />
            <div className="absolute right-0 top-0 w-1.5 h-1.5 rounded-full bg-secondary-red" />
          </div>
          <div className="flex items-center cursor-pointer">
            <img
              className="w-6 h-6 rounded-full object-cover"
              src="https://buffer.com/cdn-cgi/image/w=1000,fit=contain,q=90,f=auto/library/content/images/size/w1200/2023/10/free-images.jpg"
            />
            <ArrowDown className="w-3 h-3 ml-2 stroke-primary-black opacity-50" />
          </div>
        </div>
      </div>
    </div>
  );
}
