import { useControllableState } from "@/common/use_controllable_state";
import React, { LegacyRef } from "react";
import { StandardNumberInput } from "./standard_number_input";
import { omit } from "lodash";

interface StandardRandgeProps {
  value?: number[];
  defaultValue?: number[];
  unit?: string;
  placeholders?: string[];
  renderRightPrefix?: React.ReactNode[];
  onChange?: (state: number[]) => void;
}

export const StandardRandge = React.forwardRef(function (
  props: StandardRandgeProps,
  _: LegacyRef<HTMLInputElement>
) {
  const [value, setValue] = useControllableState({
    defaultProp: props.defaultValue,
    prop: props.value,
    onChange: props.onChange,
  });

  const nextProps = omit(props, ["defaultValue", "value"]);

  return (
    <div className="flex gap-4">
      <StandardNumberInput
        {...nextProps}
        renderRightPrefix={() => props?.renderRightPrefix?.[0]}
        unit={props.unit}
        defaultValue={value?.[0]}
        placeholder={props.placeholders?.[0]}
        onChange={(state) => setValue([state, value?.[1] || 0])}
      />
      <StandardNumberInput
        {...nextProps}
        renderRightPrefix={() => props?.renderRightPrefix?.[1]}
        unit={props.unit}
        defaultValue={value?.[1]}
        placeholder={props.placeholders?.[1]}
        onChange={(state) => setValue([value?.[0] || 0, state])}
      />
    </div>
  );
});
