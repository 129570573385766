import { useControllableState } from "@/common/use_controllable_state";
import clsx from "clsx";
import React from "react";

interface StandardToogleProps {
  value?: boolean;
  defaultValue?: boolean;
  onChange?: (state: boolean) => void;
}

export const StandardToogle = React.forwardRef(function StandardToogle(
  props: StandardToogleProps,
  _
) {
  const [checked, setChecked] = useControllableState({
    defaultProp: props.defaultValue,
    prop: props.value,
    onChange: props.onChange,
  });

  return (
    <div
      className={clsx(
        "rounded-full h-[20px] relative border cursor-pointer transition-all w-[32px] duration-500",
        {
          "bg-light-green": checked,
          "border-black": !checked,
          "border-opacity-10": !checked,
          "border-transparent": checked,
        }
      )}
      onClick={() => setChecked(!checked)}
    >
      <div
        className={clsx(
          "w-3",
          "h-3",
          "rounded-full absolute top-1/2 -translate-y-1/2 transition-all duration-500",
          {
            "bg-white": checked,
            "left-1": !checked,
            "right-1": checked,
            "bg-black": !checked,
            "bg-opacity-10": !checked,
          }
        )}
      />
    </div>
  );
});
