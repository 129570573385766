import { CardType } from "@/generated/protos/element_pb";
import { additionalOptions, resizingOptions } from ".";
import {
  StandardCheckboxGroup,
  StandardForm,
  StandardFormField,
  StandardInput,
  StandardRaidoGroup,
} from "../../form";
import { StandardUploadVideo } from "../../standard_upload_video";
import { CardProperties } from "../card_schema";
import { useForm } from "react-hook-form";

interface VideoCardPropertyProps extends Partial<CardProperties> {}

export const VideoCardProperty = (props: VideoCardPropertyProps) => {
  const form = useForm({
    defaultValues: props[CardType.CARD_TYPE_VIDEO],
  });

  return (
    <StandardForm
      form={form}
      onFormChange={(value) =>
        props?.onChange?.({ [CardType.CARD_TYPE_VIDEO]: value })
      }
    >
      <div className="flex flex-col gap-8">
        <StandardFormField<string> name="pageName" label="Page Name">
          {(props) => (
            <div className="h-9">
              <StandardInput {...props} placeholder="ex. Video" />
            </div>
          )}
        </StandardFormField>

        <StandardFormField<string>
          name="video"
          label="Upload Video"
          description="Click below or drag and drop your file in the dotted region."
        >
          <StandardUploadVideo accept={[]} />
        </StandardFormField>

        <StandardFormField<string>
          name="resizing"
          label="Resizing"
          description="How should we resize for different screen sizes."
        >
          <StandardRaidoGroup options={resizingOptions} direction="vertical" />
        </StandardFormField>

        <StandardFormField<string> name="additional" label="Additional options">
          <StandardCheckboxGroup
            options={additionalOptions}
            direction="vertical"
          />
        </StandardFormField>
      </div>
    </StandardForm>
  );
};
