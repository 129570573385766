import { ReactComponent as Ahead } from "@/assets/vectors/icn_ahead.svg";
import { ReactComponent as ImagePlaceholder } from "@/assets/vectors/icn_image_placeholder.svg";
import { ReactComponent as Integrated } from "@/assets/vectors/icn_integrated.svg";
import {
  StandardForm,
  StandardFormField,
  StandardInput,
  StandardTextarea,
  StandardThemeColor,
  StandardUploadImage,
} from "@/common";
import { useCreateCourse, useEditCourseById, useGetCoursesById } from "@/hooks";
import { pleaseWaitAtom } from "@/stores";
import clsx from "clsx";
import { useAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

export function NewCoursePage() {
  const navigate = useNavigate();
  const [_, setPleaseWait] = useAtom(pleaseWaitAtom);
  const { CreateCourses } = useCreateCourse();
  const [searchParams] = useSearchParams();
  const { courseDetail, GetCoursesById } = useGetCoursesById();
  const { EditCourseById } = useEditCourseById();

  const courseId = searchParams.get("courseId");

  const [formValue, setFormValue] = useState({
    branding: ["#AF03FF", "#000000", "#FFFFFF"],
    moduleName: "",
    logo: "",
    description: "",
    cover: "",
  });

  const form = useForm({
    values: formValue,
  });

  const onContinue = async () => {
    await form.trigger();

    try {
      if (!Object.keys(form.formState.errors).length) {
        setPleaseWait(true);

        if (courseId) {
          await EditCourseById({
            course: {
              id: courseId,
              courseName: formValue.moduleName,
              logo: formValue.logo,
              backgroundColor: formValue.branding[0],
              secondaryColor: formValue.branding[1],
              textColor: formValue.branding[2],
              description: formValue.description,
              cover: formValue.cover,
            },
          });
          navigate(`/course-detail/${courseId}`, { replace: true });
        } else {
          await CreateCourses({
            course: {
              courseName: formValue.moduleName,
              logo: formValue.logo,
              backgroundColor: formValue.branding[0],
              secondaryColor: formValue.branding[1],
              textColor: formValue.branding[2],
              description: formValue.description,
              cover: formValue.cover,
            },
          });
          navigate("/");
        }
      }
    } finally {
      setPleaseWait(false);
    }
  };

  useEffect(() => {
    if (courseId) {
      GetCoursesById({ id: courseId });
    }
  }, [courseId]);

  useEffect(() => {
    if (courseDetail?.id === courseId) {
      setFormValue({
        branding: [
          courseDetail.backgroundColor,
          courseDetail.secondaryColor,
          courseDetail.textColor,
        ],
        moduleName: courseDetail.courseName,
        logo: courseDetail.logo,
        description: courseDetail.description,
        cover: courseDetail.cover,
      });
    }
  }, [courseDetail]);

  const title = useMemo(
    () => (courseId ? "Edit Module" : "New Module"),
    [courseId]
  );

  return (
    <div className="w-[960px] pb-10">
      <StandardForm form={form} onFormChange={(value) => setFormValue(value)}>
        <div className="sticky top-[64px] pt-10 bg-white z-10">
          <div className="flex justify-between items-end gap-8">
            <div className="flex flex-col h-[74px] justify-between">
              <span className="text-[34px] font-semibold">{title}</span>
              <span className="text-xs">
                You have <span className="font-bold">4 things</span> to complete
              </span>
            </div>
            <div className="flex gap-4">
              <button
                type="submit"
                className="flex items-center justify-center w-[120px] h-9 bg-primary-black rounded-2xl cursor-pointer hover:opacity-70 transition-all duration-500"
                onClick={onContinue}
              >
                <span className="font-medium text-sm text-white">Continue</span>
                <Ahead className="h-4 w-4 stroke-white ml-2.5" />
              </button>
              <div
                className="rounded-2xl w-20 h-9 flex items-center justify-center border border-black border-opacity-10 font-medium text-sm cursor-pointer"
                onClick={() => navigate(-1)}
              >
                Cancel
              </div>
            </div>
          </div>
          <div className="my-9 w-full h-[1px] bg-gray"></div>
        </div>

        <div className="w-full flex gap-12">
          <div className="flex-1 flex flex-col">
            <div className="flex flex-col gap-8">
              <StandardFormField<string>
                registerOptions={{ required: "This field is required" }}
                name={"moduleName"}
                label="Module Name"
                description="What’s your assistants background, profession, and expertise?"
              >
                {(props) => (
                  <div className="h-9">
                    <StandardInput
                      placeholder="ex. Welcome to Figma"
                      {...props}
                    />
                  </div>
                )}
              </StandardFormField>

              <StandardFormField<string>
                registerOptions={{ required: "This field is required" }}
                name={"logo"}
                label="Logo"
                description="Upload an image that visually represents your course."
              >
                {(props) => (
                  <div className="w-[88px] h-[88px] rounded-full border border-black border-dashed border-opacity-10 overflow-hidden">
                    <StandardUploadImage accept={[]} {...props}>
                      <div className="flex flex-col items-center">
                        <ImagePlaceholder className="w-4 h-4 opacity-50 stroke-primary-black" />
                        <span className="text-[10px] opacity-50 mt-2 leading-3">
                          Upload
                        </span>
                      </div>
                    </StandardUploadImage>
                  </div>
                )}
              </StandardFormField>

              <StandardFormField
                registerOptions={{ required: "This field is required" }}
                name={"branding"}
                label="Default branding"
                description="Set the default look and feel; you can customize per card later."
              >
                <StandardThemeColor />
              </StandardFormField>

              <StandardFormField
                registerOptions={{ required: "This field is required" }}
                name={"description"}
                label="Description"
                description="What’s your assistants background, profession, and expertise?"
              >
                <StandardTextarea placeholder="Multi Line Text" />
              </StandardFormField>

              <StandardFormField<string>
                registerOptions={{ required: "This field is required" }}
                name={"cover"}
                label="Cover Image"
                description="Select a cover image to represent your course visually.​"
              >
                {(props) => (
                  <div className="w-[420px] h-[160px] rounded-2xl border border-black border-dashed border-opacity-10 overflow-hidden">
                    <StandardUploadImage accept={[]} {...props}>
                      <div className="flex flex-col items-center">
                        <ImagePlaceholder className="w-4 h-4 opacity-50 stroke-primary-black" />
                        <span className="text-[10px] opacity-50 mt-2 leading-3">
                          Upload an image
                        </span>
                      </div>
                    </StandardUploadImage>
                  </div>
                )}
              </StandardFormField>
            </div>
          </div>
          <div className="flex-1 flex flex-col text-xs">
            <div className="flex flex-col">
              <span className="font-bold">Course Preview</span>
              <span className="font-medium opacity-50 mt-1 mb-4">
                Watch a live preview of your course as you input values, giving
                you a clear view of how everything will look to your learners.
              </span>
              <div className="h-[200px] rounded-2xl bg-gray relative overflow-hidden">
                <div
                  className="absolute left-1/2 -bottom-10 -translate-x-1/2 w-[344px] h-[200px] bg-white rounded-2xl p-[18px] flex flex-col items-center"
                  style={{ boxShadow: "0px 0px 18px rgba(0, 0, 0, 0.1)" }}
                >
                  <div className="relative w-full h-[60px] border border-black border-opacity-10 border-dashed rounded-2xl">
                    {formValue?.cover && (
                      <img
                        className="w-full h-full object-cover rounded-2xl"
                        src={`/api/v1/files/${formValue?.cover}`}
                      />
                    )}
                    <div className="w-[58px] h-[58px] rounded-full bg-gray flex items-center justify-center absolute left-1/2 -translate-x-1/2 -bottom-[30px] overflow-hidden">
                      {formValue["logo"] ? (
                        <img
                          className="w-full h-full rounded-lg object-cover"
                          src={`/api/v1/files/${formValue?.logo}`}
                        />
                      ) : (
                        <ImagePlaceholder className="w-4 h-4 stroke-primary-black opacity-50" />
                      )}
                    </div>
                  </div>
                  <span className="mt-10 font-semibold opacity-20 text-[22px]">
                    {formValue["moduleName"] || "Module Title"}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col mt-[54px]">
              <span className="font-bold">Module Preview</span>
              <span className="font-medium opacity-50 mt-1 mb-4">
                See how a default card in your course will appear.
              </span>
              <div className="h-[236px] rounded-2xl bg-gray relative overflow-hidden">
                <div className="absolute -right-[90px] -bottom-16 w-[345px] h-[228px] bg-white rounded-2xl p-4 flex">
                  <div className="w-20 h-full py-1 px-2">
                    <div className="flex items-center justify-between mb-3.5">
                      <div className="w-4 h-4 rounded-full bg-gray overflow-hidden">
                        {formValue["logo"] ? (
                          <img
                            className="w-full h-full rounded-lg object-cover"
                            src={`/api/v1/files/${formValue?.logo}`}
                          />
                        ) : null}
                      </div>
                      <div className="w-10 h-1 bg-light-gray rounded-full" />
                    </div>
                    {Array.from({ length: 8 }).map((_, index) => (
                      <div className="flex justify-between h-5" key={index}>
                        <div className="w-11 h-1 bg-light-gray rounded-full" />
                        <div className="w-1 h-1 rounded-full bg-light-gray" />
                      </div>
                    ))}
                  </div>
                  <div
                    className={clsx(
                      "ml-2 h-full flex-1 flex flex-col justify-center items-center rounded-2xl transition-all duration-500"
                    )}
                    style={{ backgroundColor: formValue["branding"][0] }}
                  >
                    <Integrated
                      className={clsx("w-4 h-4 stroke-primary-black")}
                    />
                    <span className="w-[70px] leading-3 text-[10px] mt-1 text-center">
                      Apply brand to preview
                    </span>
                  </div>
                </div>
                <div
                  className="absolute left-[64px] -bottom-[74px] w-[172px] h-[254px] bg-white rounded-2xl p-4 -rotate-[4deg]"
                  style={{ boxShadow: "0px 0px 18px rgba(0, 0, 0, 0.1)" }}
                >
                  <div
                    className="bg-gray rounded-2xl w-full h-full flex flex-col items-center justify-center transition-all duration-500"
                    style={{ backgroundColor: formValue["branding"][0] }}
                  >
                    <Integrated className="w-4 h-4 stroke-primary-black" />
                    <span className="w-[70px] leading-3 text-[10px] mt-1 text-center">
                      Apply brand to preview
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StandardForm>
    </div>
  );
}
