import { unexpectedErrorAtom } from "@/stores";
import clsx from "clsx";
import { useAtom } from "jotai";

export function StandardUnexpectedError() {
  const [value, setValue] = useAtom(unexpectedErrorAtom);

  return (
    <>
      <dialog className={clsx("modal", { "modal-open": value })}>
        <div className="modal-box w-fit text-base px-10 py-6" role="dialog">
          Unexpected Error
        </div>
        <form method="dialog" className="modal-backdrop">
          <button onClick={() => setValue(false)} />
        </form>
      </dialog>
    </>
  );
}
