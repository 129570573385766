import { router } from "@/router";
import { unexpectedErrorAtom } from "@/stores";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
  Method,
} from "axios";
import $pb from "google-protobuf";
import { getDefaultStore } from "jotai";

interface ApiResponse<T = unknown> {
  code: number;
  msg: string;
  data: T;
}

interface ExAxiosRequestConfig<S = unknown>
  extends Omit<AxiosRequestConfig<S>, "method"> {
  method: Method;
  message: $pb.Message;
}

export class ApiClient {
  private axiosInstance: AxiosInstance;

  private static instance: ApiClient;

  private store = getDefaultStore();

  private constructor(config?: CreateAxiosDefaults) {
    this.axiosInstance = axios.create(config);

    this.axiosInstance.interceptors.request.use(
      this.processRequestSuccess.bind(this),
      (error) => Promise.reject(error)
    );

    this.axiosInstance.interceptors.response.use(
      this.processResponseSuccess.bind(this),
      this.processResponseError.bind(this)
    );
  }

  public static getInstance(config?: CreateAxiosDefaults) {
    if (!this.instance) {
      return (this.instance = new ApiClient(config));
    }

    return this.instance;
  }

  private async requestBase<T, S = unknown, D = unknown>(
    requestConfig: Partial<ExAxiosRequestConfig<S>>
  ): Promise<T> {
    const response = await this.axiosInstance.request<
      D,
      AxiosResponse<T, D>,
      S
    >({
      ...requestConfig,
      headers: {
        "Content-Type": "application/json",
        ...(requestConfig.headers || {}),
      },
    });

    return response.data;
  }

  public async postProtoMessage(
    requestConfig: Partial<ExAxiosRequestConfig>
  ): Promise<Uint8Array> {
    return await this.requestBase<Uint8Array, Uint8Array>({
      ...requestConfig,
      data: requestConfig?.message?.serializeBinary(),
      responseType: "arraybuffer",
      method: "POST",
      headers: {
        "Content-Type": "application/x-protobuf",
      },
    });
  }

  public async postFormDataMessage<T>(
    requestConfig: Partial<ExAxiosRequestConfig>
  ): Promise<T> {
    return await this.requestBase({
      ...requestConfig,
      data: requestConfig?.data,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  private processRequestSuccess(config: InternalAxiosRequestConfig) {
    return config;
  }

  private processResponseError(error: AxiosError) {
    const status = error.response?.status;
    const message = error.message;

    this.handleStatusException(status!, message);

    return Promise.reject(error);
  }

  private async processResponseSuccess(response: AxiosResponse<ApiResponse>) {
    return response;
  }

  private handleStatusException(status: number, error: string) {
    switch (status) {
      case 500: {
        this.store.set(unexpectedErrorAtom, true);
        break;
      }
      case 401: {
        router.navigate("/login-or-signup");
        break;
      }
      case 404: {
        this.store.set(unexpectedErrorAtom, true);
        break;
      }
      default: {
        console.error("Unexpected error", error);
      }
    }
  }
}
