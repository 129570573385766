import { ReactComponent as Share } from "@/assets/vectors/icn_share.svg";
import { ReactComponent as Settings } from "@/assets/vectors/icn_settings.svg";
import { ReactComponent as Metrics } from "@/assets/vectors/icn_metrics.svg";
import { ReactComponent as Eye } from "@/assets/vectors/icn_eye.svg";
import { ReactComponent as Outline } from "@/assets/vectors/icn_outline.svg";
import { ReactComponent as Edit } from "@/assets/vectors/icn_edit.svg";
import { ReactComponent as Delete } from "@/assets/vectors/icn_delete.svg";
import { ReactComponent as More } from "@/assets/vectors/icn_more.svg";
import { ReactComponent as ImagePlaceHolder } from "@/assets/vectors/icn_image_placeholder.svg";
import { ReactComponent as Duplicate } from "@/assets/vectors/icn_duplicate.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteCardById,
  useGetCoursesById,
  useListCardByCourseId,
  useDeleteCourseById,
  useEditCourseById,
} from "@/hooks";
import { useEffect, useMemo } from "react";
import { StandardLoadingIndicator } from "@/common";
import { CardBaseSchema, cardMap } from "@/common/components/cards";
import { useAtom } from "jotai";
import { currentCardAtom } from "@/stores/course_atom";
import { pleaseWaitAtom } from "@/stores";
import {
  DropdownMenuItemProps,
  StandardDropdownMenuType,
  StandardDropdownMenu,
  StandardUploadImage,
  StandardDropdownMenuPosition,
} from "@/common/components";
import clsx from "clsx";

export function CourseDetailPage() {
  const navigate = useNavigate();
  const [_, setCurrentCard] = useAtom(currentCardAtom);
  const [__, setPleaseWait] = useAtom(pleaseWaitAtom);

  const {
    pending: coursePending,
    courseDetail,
    GetCoursesById,
  } = useGetCoursesById();

  const { DeleteCardById } = useDeleteCardById();
  const { DeleteCourseById } = useDeleteCourseById();
  const { EditCourseById } = useEditCourseById();

  const {
    pending: cardPending,
    cardsByCourseId,
    ListCardByCourseId,
  } = useListCardByCourseId();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      GetCoursesById({ id });
      ListCardByCourseId({ courseId: id });
    }
  }, [id]);

  const onEdit = (card: CardBaseSchema) => {
    setCurrentCard(card);
    navigate(`/build-course/${id}`);
  };

  const options = useMemo<DropdownMenuItemProps[][]>(() => {
    return [
      [
        {
          label: "Basic info",
          type: StandardDropdownMenuType.default,
          icon: Settings,
          onClick: () => {
            const params = new URLSearchParams({
              courseId: id!,
            });
            navigate(`/new-course?${params.toString()}`);
          },
        },
      ],
      [
        {
          label: "Edit Course",
          type: StandardDropdownMenuType.default,
          icon: Edit,
          onClick: () => {
            const params = new URLSearchParams({
              courseId: id!,
            });
            navigate(`/build-course/${id}`);
          },
        },
        {
          label: "Duplicate",
          type: StandardDropdownMenuType.default,
          icon: Duplicate,
        },
        {
          label: "Delete",
          type: StandardDropdownMenuType.error,
          icon: Delete,
          onClick: async () => {
            try {
              setPleaseWait(true);
              await DeleteCourseById({ id });
              navigate("/");
            } finally {
              setPleaseWait(false);
            }
          },
        },
      ],
    ];
  }, [navigate, id]);

  const onDelete = async (card: CardBaseSchema) => {
    try {
      setPleaseWait(true);
      await DeleteCardById({ id: card.id });
      await ListCardByCourseId({ courseId: id! });
    } finally {
      setPleaseWait(false);
    }
  };

  const onCoverChange = async (image: string) => {
    try {
      setPleaseWait(true);
      await EditCourseById({ course: { ...courseDetail, cover: image } });
      await GetCoursesById({ id: id! });
    } finally {
      setPleaseWait(false);
    }
  };

  return (
    <div className="w-[1200px] py-6">
      {coursePending && !courseDetail ? (
        <StandardLoadingIndicator />
      ) : (
        <div className="w-full h-[222px] relative rounded-2xl">
          <div className="relative w-full h-full rounded-lg overflow-hidden">
            <img
              className="w-full h-full object-cover absolute left-0 right-0"
              src={`/api/v1/files/${courseDetail?.cover}`}
            />
            <div className="absolute left-0 top-0 bg-black opacity-50 w-full h-full"></div>
          </div>

          <div className="absolute left-0 top-8 z-10 flex justify-between w-full px-8">
            <div className="flex">
              <div className="w-[88px] h-[88px] rounded-lg border-2 border-white flex-shrink-0 mr-8">
                <img
                  className="w-full h-full rounded-lg object-cover"
                  src={`/api/v1/files/${courseDetail?.logo}`}
                />
              </div>
              <div className="flex flex-col justify-between">
                <span className="font-bold text-2xl text-white">
                  {courseDetail?.courseName}
                </span>
                <span className="text-xs font-medium text-white w-[640px]">
                  {courseDetail?.description}
                </span>
              </div>
            </div>
            <div className="w-[128px]">
              <StandardUploadImage
                accept={[]}
                onChange={onCoverChange}
                disabledPreview={true}
              >
                <div className="flex items-center h-9 px-4 rounded-2xl bg-white cursor-pointer">
                  <span className="text-primary-black font-medium text-sm">
                    Edit Cover
                  </span>
                  <ImagePlaceHolder className="stroke-primary-black w-4 h-4 ml-2.5" />
                </div>
              </StandardUploadImage>
            </div>
          </div>

          <div className="absolute bottom-8 right-8 flex justify-center gap-4">
            <div
              className="w-[115px] h-9 bg-primary text-white flex items-center justify-center font-medium text-sm rounded-2xl cursor-pointer"
              onClick={() => navigate(`/build-course/${id}`)}
            >
              Start course
            </div>
            <div
              className={clsx(
                "w-[115px] h-9 bg-white flex justify-center items-center rounded-2xl cursor-pointer",
                {
                  "pointer-events-none": !cardsByCourseId.length,
                }
              )}
              onClick={() => navigate(`/preview/${id}`)}
            >
              <span className="text-sm font-medium">Share</span>
              <Share className="w-4 h-4 stroke-primary-black ml-2.5" />
            </div>
            <StandardDropdownMenu
              options={options}
              position={StandardDropdownMenuPosition.bottom_end}
            >
              <div className="w-[115px] h-9 bg-white flex justify-center items-center rounded-2xl cursor-pointer">
                <span className="text-sm font-medium">Settings</span>
                <Settings className="w-4 h-4 stroke-primary-black ml-2.5" />
              </div>
            </StandardDropdownMenu>
          </div>
        </div>
      )}

      <div className="h-10 w-full"></div>

      <div className="flex gap-10">
        <div className="flex flex-col flex-1">
          <div className="flex items-center opacity-50">
            <Metrics className="w-6 h-6 stroke-primary-black mr-2" />
            <span className=" text-black font-semibold text-sm">
              Key metrics
            </span>
          </div>

          <div className="flex w-full text-black mt-6 gap-2.5">
            <div className="flex flex-col flex-1 rounded-2xl border border-stone-gray h-[192px] py-8 px-6">
              <span className="opacity-50 text-xs leading-3">Total views</span>
              <span className="text-2xl leading-[30px] mt-3 font-semibold">
                1,278
              </span>
            </div>
            <div className="flex flex-col flex-1 rounded-2xl border border-stone-gray h-[192px] py-8 px-6">
              <span className="opacity-50 text-xs leading-3">
                Views this week
              </span>
              <span className="text-2xl leading-[30px] mt-3 font-semibold">
                2
              </span>
            </div>
            <div className="flex flex-col flex-1 rounded-2xl border border-stone-gray h-[192px] py-8 px-6">
              <span className="opacity-50 text-xs leading-3">
                Avg. Time to Complete
              </span>
              <span className="text-2xl leading-[30px] mt-3 font-semibold">
                4m 12s
              </span>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="flex items-center opacity-50">
            <Eye className="w-4 h-4 stroke-primary-black mr-2" />
            <span className=" text-black font-semibold text-sm">
              Views by page
            </span>
          </div>

          <div className="rounded-2xl border border-stone-gray h-[192px] mt-6 px-8 py-6 flex justify-between">
            {Array.from({ length: 10 }).map((_, index) => (
              <div key={index} className="w-6">
                <div className="h-[126px] flex items-center overflow-hidden">
                  <div className="w-full bg-primary rounded-lg h-[112px]" />
                </div>
                <div className="mt-2 text-sm font-semibold text-black">82</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="h-10 w-full"></div>

      <div className="flex flex-col">
        <div className="flex items-center opacity-50">
          <Outline className="w-6 h-6 stroke-primary-black mr-2" />
          <span className=" text-black font-semibold text-sm">
            Course outline
          </span>
        </div>

        {cardPending && !cardsByCourseId.length ? (
          <StandardLoadingIndicator />
        ) : (
          <div className="mt-6 flex flex-col">
            {cardsByCourseId.map((card, index) => {
              const cardSchema = cardMap[card.type!];
              const Icon = cardSchema.icon;
              return (
                <div
                  key={index}
                  className="flex justify-between items-center py-5 border-b border-silver-mist"
                >
                  <div className="flex items-center">
                    <div className=" bg-charcoal-black opacity-30 rounded-full w-4 h-4 flex items-center justify-center text-white text-[10px]">
                      {index + 1}
                    </div>
                    <div className="w-[88px] h-[48px] rounded-lg bg-primary flex items-center justify-center mx-8">
                      <span className="text-white  text-[6px]">
                        Preview here
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className=" text-base text-black font-semibold leading-5">
                        Your First Day
                      </span>
                      <div className="opacity-50 flex items-center mt-1">
                        <Icon className="w-4 h-4 stroke-primary-black" />
                        <span className="ml-1.5 text-xs">
                          {cardSchema.label}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="flex items-center bg-gray rounded-2xl h-7 w-[120px] justify-center">
                      <span className="text-sm">1.2k Views</span>
                      <Eye className="stroke-primary-black ml-2 w-4" />
                    </div>
                    <div className="ml-[308px] flex items-center gap-6">
                      <Edit
                        className="w-6 stroke-primary-black opacity-50 cursor-pointer"
                        onClick={() => onEdit(card)}
                      />
                      <Delete
                        className="w-6 stroke-primary-black opacity-50 cursor-pointer"
                        onClick={() => onDelete(card)}
                      />
                      <More className="w-6 stroke-primary-black opacity-50" />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
