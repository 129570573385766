import { createBrowserRouter } from "react-router-dom";
import {
  AllCoursesPage,
  BuildCoursePage,
  CourseDetailPage,
  DashboardRoot,
  LoginOrSignupPage,
  NewCoursePage,
  PreviewPage,
} from "./components";

export const router = createBrowserRouter([
  {
    path: "/login-or-signup",
    element: <LoginOrSignupPage />,
  },
  {
    path: "/",
    element: <DashboardRoot />,
    children: [
      {
        path: "/",
        element: <AllCoursesPage />,
      },
      {
        path: "/build-course/:id",
        element: <BuildCoursePage />,
      },
      {
        path: "/course-detail/:id",
        element: <CourseDetailPage />,
      },
      {
        path: "/new-course",
        element: <NewCoursePage />,
      },
    ],
  },
  {
    path: "/preview/:id",
    element: <PreviewPage />,
  },
]);
