// source: element.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.journeyproto.CardType', null, global);
/**
 * @enum {number}
 */
proto.journeyproto.CardType = {
  CARD_TYPE_NAME: 0,
  CARD_TYPE_FREE_RESPONSE: 1,
  CARD_TYPE_ADDRESS: 2,
  CARD_TYPE_EMAIL: 3,
  CARD_TYPE_VIDEO: 4,
  CARD_TYPE_PHONE_NUMBER: 5,
  CARD_TYPE_RATING: 6,
  CARD_TYPE_SIGNATURE: 7,
  CARD_TYPE_TEXT_MATCH: 8,
  CARD_TYPE_FILE_INPUT: 9,
  CARD_TYPE_PHOTO: 10
};

goog.object.extend(exports, proto.journeyproto);
