import clsx from "clsx";
import React from "react";
import { HTMLAttributes, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

interface StandardCardUploadFileProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "onChange"> {
  accept: string[];
  onChange?: (state: string) => void;
}

export const StandardCardUploadFile = React.forwardRef(function (
  props: StandardCardUploadFileProps,
  _
) {
  const [imageUrl, __] = useState<string>("");

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": props.accept,
    },
    maxFiles: 1,
    maxSize: 1024 * 2,
  });

  return (
    <div className="w-full h-full flex items-center justify-center">
      {!imageUrl ? (
        <div
          {...getRootProps()}
          className={clsx(
            "w-full h-full flex items-center justify-center cursor-pointer transition-all",
            {
              "opacity-50": isDragActive,
            },
            props.className
          )}
        >
          <input {...getInputProps()} />

          {props.children}
        </div>
      ) : (
        <div className="w-full h-full relative">
          <img src={imageUrl} className="w-full h-full object-contain" />
        </div>
      )}
    </div>
  );
});
