import { ReactComponent as Phone } from "@/assets/vectors/icn_phone.svg";
import { PhoneNumberCardProperty } from "./property";
import { PhoneNumberCard } from "./card";
import { CardSchema } from "../card_schema";
import { CardType } from "@/generated/protos/element_pb";

export interface PhoneNumberCardProperties {
  label: string;
  supportingText: string;
  placeholder: string;
  required: boolean;
}

export const phoneNumberCardConfig: CardSchema = {
  id: "",
  type: CardType.CARD_TYPE_PHONE_NUMBER,
  label: "Phone Number",
  icon: Phone,
  properties: {
    [CardType.CARD_TYPE_PHONE_NUMBER]: {
      label: "",
      supportingText: "",
      placeholder: "",
      required: true,
    },
  },
  enabledGestures: false,
  card: PhoneNumberCard,
  property: PhoneNumberCardProperty,
};
