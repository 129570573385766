import { ReactComponent as Video } from "@/assets/vectors/icn_video.svg";

import { VideoCard } from "./card";
import { VideoCardProperty } from "./property";
import { CardSchema } from "../card_schema";
import { CardType } from "@/generated/protos/element_pb";

export interface VideoCardProperties {
  pageName: string;
  video: string;
  resizing: number;
  additional: number[];
}

export const resizingOptions = [
  {
    label: "Fixed",
    value: 0,
  },
  {
    label: "Filled",
    value: 1,
  },
];

export const additionalOptions = [
  {
    label: "Disable skipping",
    value: 0,
  },
  {
    label: "Show video progress bar",
    value: 1,
  },
];

export const videoCardConfig: CardSchema = {
  id: "",
  type: CardType.CARD_TYPE_VIDEO,
  label: "Video",
  icon: Video,
  properties: {
    [CardType.CARD_TYPE_VIDEO]: {
      pageName: "",
      video: "",
      resizing: 0,
      additional: [],
    },
  },
  enabledGestures: true,
  card: VideoCard,
  property: VideoCardProperty,
};
