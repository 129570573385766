import { ReactComponent as Signature } from "@/assets/vectors/icn_signature.svg";
import { SignatureCard } from "./card";
import { SignatureCardProperty } from "./property";
import { CardSchema } from "../card_schema";
import { CardType } from "@/generated/protos/element_pb";

export interface SignatureCardProperties {
  label: string;
  supportingText: string;
  required: boolean;
  signature: string;
}

export const signatureCardConfig: CardSchema = {
  id: "",
  type: CardType.CARD_TYPE_SIGNATURE,
  label: "Signature",
  icon: Signature,
  properties: {
    [CardType.CARD_TYPE_SIGNATURE]: {
      label: "",
      supportingText: "",
      required: true,
      signature: "",
    },
  },
  enabledGestures: false,
  card: SignatureCard,
  property: SignatureCardProperty,
};
