import { useControllableState } from "@/common/use_controllable_state";
import clsx from "clsx";
import React from "react";
import { ReactComponent as UnChecked } from "@/assets/vectors/icn_unchecked.svg";
import { ReactComponent as Checked } from "@/assets/vectors/icn_checked.svg";

interface StandardCheckboxGroupOption {
  label: string;
  value: string | number;
}

interface StandardCheckboxGroupProps {
  defaultValue?: (string | number)[];
  options: StandardCheckboxGroupOption[];
  direction: "horizontal" | "vertical";
  onChange?: (state: (string | number)[]) => void;
}

export const StandardCheckboxGroup = React.forwardRef(function (
  props: StandardCheckboxGroupProps,
  _
) {
  const [value, setValue] = useControllableState({
    defaultProp: props.defaultValue || [],
    onChange: props.onChange,
  });

  const onChange = (option: StandardCheckboxGroupOption) => {
    if (value?.includes(option.value)) {
      setValue(value.filter((v) => v !== option.value));
      return;
    }

    setValue(value?.concat(option.value));
  };

  return (
    <div
      className={clsx("flex", "gap-4", {
        "flex-col": props.direction === "vertical",
        "flex-row": props.direction === "horizontal",
      })}
    >
      {props.options.map((option) => (
        <div
          key={option.value}
          className="cursor-pointer flex items-center"
          onClick={() => onChange(option)}
        >
          {value?.includes?.(option.value) ? (
            <Checked className="stroke-white fill-primary-black w-4" />
          ) : (
            <UnChecked className="stroke-primary-black opacity-50 w-4" />
          )}

          <span className="text-sm text-primary-black ml-4 leading-[18px]">
            {option.label}
          </span>
        </div>
      ))}
    </div>
  );
});
